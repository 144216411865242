
import { Component, Vue, Watch, Provide } from "vue-property-decorator";
import {
  saveCollection,
  saveFootPrint,
  getJsInfo,
  getIndexMapUrl,
  sendQrpvCount,
  submitListenRecord,
  getBagDetail,
  getisSubscribe,
  getuploadossKey,
  saveTouristStay,
  getVerificationCode,
  userLogin,
  getServiceConfig,
  getSpotVoiceData,
  getRouteVoiceData,
  getScenicVoiceData,
  getMultiVoice,
  getVoiceDetailInfos,
  getLineVoiceDetailInfos,
  getLineVoiceData,
  getFlowerSpotVoiceData,
  getFlowerVoiceDetailInfos
} from "@/service/index";
import privacy from "./views/privacy-agreement/index.vue";
import comm from "@/util/common";
import CONFIG from "@/util/config";
import Weixin from "@/util/user";
// import socketio from 'socket.io-client';
declare let AMap: any;
declare let qq: any;
declare let wx: any;
declare let my: any;
import { Toast } from "vant";
import axios from "axios";
import { fail } from "node:assert";
import utils from "@/util/util";
import { Console } from "node:console";
Vue.use(Toast);
interface bagData {
  isbuy: boolean;
  title: string;
  num: string | number;
  voicePacketId: string | number;
}
@Component({
  components: { privacy }
})
export default class App extends Vue {
  bus: any;
  $refs!: {
    loginFormRef: HTMLFormElement;
    audio: HTMLFormElement;
  };
  // 语音类型
  private LanguageType: any = [
    {
      label: "中文",
      value: 0
    }
  ];
  // 解说类型
  private InterpretationType: any = [
    {
      label: "不限",
      value: 0
    }
  ];
  // 声音类型
  private SoundType: any = [
    {
      label: "男声",
      value: 0
    },
    {
      label: "女声",
      value: 1
    }
  ];
  // 手机验证弹窗表单
  private loginForm: any = {
    tel: "", //电话号码
    verificationCode: "", //表单中展示的验证码
    contenttext: "", //向手机号发送的随机验证码
    timer: null, //倒计时时间
    showloginCode: true, //判断展示‘获取验证码’或‘倒计时’
    count: "", //倒计时时间
    verification: "发送验证码", //验证码
    title: ``,
    checkboxGroup: false
  };
  private popoverActions: any = [
    { text: "选项一", icon: "add-o" },
    { text: "选项二", icon: "music-o" },
    { text: "选项三", icon: "more-o" }
  ];
  private isabled = false; //是否禁用发送验证码按钮
  private routerUrl: any; //用户登录后要跳转的页面
  private socket: any;
  private siteCode: any = "";
  private privacyVisibly: boolean = false;
  private isAlert: boolean = false;
  private isAlert1: boolean = false;
  private privacyTimer: any = null;
  private showPopover: boolean = false;
  private isshowPopover: boolean = false;
  private voiceLists: [] = [];
  private routePoint: any = { currrentPlayStatus: false };
  private isplayorpause: boolean = false;
  private lineVoiceData: [] = [];
  private currentVoiceType: Number = -1; // 当前语音播放类型
  private currentInterpretationType: Number = -1; // 当前解说类型
  private currentSoundType: Number = -1; //  当前声音类型
  created() {
    this.routerUrl = "";
    this.getJsInfo();
    this.initIPLocation();
    this.initLocation();
    if (this.getQueryVariable("vcode")) {
      localStorage.setItem("vcode", this.getQueryVariable("vcode"));
    }
    if (this.getQueryVariable("token")) {
      localStorage.setItem(
        "token" + localStorage.getItem("vcode"),
        this.getQueryVariable("token")
      );
    }
    if (this.getQueryVariable("openId")) {
      localStorage.setItem("openId", this.getQueryVariable("openId"));
    }
    // 重置是否在景区标识
    localStorage.setItem("inSenic", "false");
    // 获取、校验token 及获取用户信息
    if (
      this.GetQueryString("token") != null &&
      (this.GetQueryString("token") as any).toString().length > 1
    ) {
      localStorage.setItem(
        "token",
        (this.GetQueryString("token") as any).toString()
      );
    } else {
      Weixin.getUserInfo();
    }
  }
  mounted() {
    this.routerUrl = "";
    this.bus.$on("getSpotVoiceData", (point: Object) => {
      this.setScenicMarkerIcon(true, this.activePoint);
      this.activePoint = point;
      this.getSpotVoiceData(point);
      // 恢复原来的icon
      // Object.keys(this.activePoint).length && this.setScenicMarkerIcon(true, this.activePoint);
    });
    this.bus.$on("getFlowerSpotVoiceData", (point: Object) => {
      this.setScenicMarkerIcon(true, this.activePoint);
      this.activePoint = point;
      this.getFlowerSpotVoiceData(point);
    });
    this.bus.$on("setCurrentVoice", (data: Object, id: any) => {
      this.currentVoice = {
        // @ts-ignore
        gideHeadImage: (data.bag && data.bag.headImg) || data.gideHeadImage,
        voicePacketId: id
      };
    });
    // 线路解说
    this.bus.$on("getRouteVoiceData", (point: Object) => {
      // this.setScenicMarkerIcon(true, this.activePoint);
      this.activePoint = point;
      this.getRouteVoiceData(point);
    });
    this.initLocation();
    // 若为扫描二维码进入页面 则调用接口进行统计
    let qrCode = this.getQueryVariable("qrcode");
    if (qrCode) {
      sendQrpvCount({ qrCode: qrCode });
    }
    // 微信浏览器中 或是导览页、景点详情页 是否关注公众号，未关注则弹窗提示关注
    if (
      comm.isWechatBrowsers() &&
      this.$store.state.firstShowAD &&
      (this.$route.name === "scenic-guide" ||
        this.$route.name === "scenic-detial-info")
    ) {
      getisSubscribe().then((res: any) => {
        if (res.code === 0 && !res.data.subscribe && res.data.subscribeBiz) {
          this.$dialog
            .confirm({
              message: "关注公众号\n随时访问景区资源？",
              confirmButtonText: "去关注"
            })
            .then(() => {
              window.location.href = decodeURIComponent(res.data.subscribeBiz);
            });
          this.$store.commit("setFirstShowAD");
        }
      });
    }
    // 计算用户位置是否在某个景点范围
    setInterval(() => {
      this.computeInSecnic();
    }, 30 * 1000);
    this.animatArrowImg();
    this.siteCode = window.location.href.split(".")[0].split("//")[1];
    // websock连接
    if (localStorage.getItem("token")) {
      this.socket = (window as any).io(
        `https://yty.daqsoft.com/?token=${localStorage.getItem(
          "token"
        )}&siteCode=${this.siteCode}`,
        // `https://yty.test.daqsoft.com/?token=${localStorage.getItem(
        //   "token"
        // )}&siteCode=jq`,
        {
          path: "/v2/socket.io",
          // path: "/socket.io",
          transports: ["websocket"]
        }
      );
      this.socket.on("loginAck", (data: any) => {
        //
        sessionStorage.setItem("connectId", data.data);
        // sessionStorage.setItem("siteCode", "site000008");
      });
      this.socket.on("onlineEvent", (data: any) => {});
      this.socket.on("msg", (data: any) => {
        //
        this.$store.commit("setRealMessage", data);
      });
      this.socket.on("connect_failed", () => {});
    }
    // 解决ios软键盘收起后，body被顶起不回落，使底部按钮点击失败
    document.body.addEventListener("focusout", () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
    this.getCustomShow();
    this.getMultiVoice();
    this.$store.commit("setShowVerification", false);
    // 如果是支付宝环境引入js文件调用api
    if (/AlipayClient/i.test(navigator.userAgent)) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://appx/web-view.min.js";
      document.body.appendChild(script);
    }
  }
  beforeDestroy() {
    // 移除监听
    document.body.removeEventListener("focusout", () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
    window.clearTimeout(this.privacyTimer);
    this.privacyTimer = null;
    this.routerUrl = "";
  }
  private chooseVoiceType() {
    this.showPopover = true;
    let ids = 0;
    if (this.$store.state.saveRouterPath === "line") {
      ids = this.routePoint.id;
    } else if (this.$store.state.saveRouterPath === "flowerspot") {
      ids = this.$store.state.currentCommentarySpot.flowerId;
    } else {
      ids = this.$store.state.currentCommentarySpot.spotId;
    }
    this.LanguageType = [];
    this.InterpretationType = [];
    this.SoundType = [];
    if (!ids) {
      return false;
    }
    if (this.$store.state.saveRouterPath === "line") {
      getLineVoiceDetailInfos({ routeId: ids }).then(async res => {
        // this.$store.commit("setShowVerification", false);
        if (res.code == 0) {
          this.LanguageType = res.datas || [];
          let index = res.datas.findIndex(
            (itm: any) => itm.value == this.currentVoiceType
          );
          await this.switchVoice(1, index, 0);
          if (
            this.LanguageType.length > 0 &&
            this.LanguageType[index].childList &&
            this.LanguageType[index].childList.length > 0
          ) {
            let index1 = this.LanguageType[index].childList.findIndex(
              (itm: any) => itm.value == this.currentInterpretationType
            );
            this.switchVoice(2, index1, 0);
          } else {
            this.SoundType = [];
          }
        }
      });
    } else if (this.$store.state.saveRouterPath === "flowerspot") {
      getFlowerVoiceDetailInfos({ flowerSpotId: ids }).then(async res => {
        this.$store.commit("setShowVerification", false);
        if (res.code == 0) {
          this.LanguageType = res.datas || [];
          let index = res.datas.findIndex(
            (itm: any) => itm.value == this.currentVoiceType
          );
          await this.switchVoice(1, index, 0);
          if (
            this.LanguageType.length > 0 &&
            this.LanguageType[index].childList &&
            this.LanguageType[index].childList.length > 0
          ) {
            let index1 = this.LanguageType[index].childList.findIndex(
              (itm: any) => itm.value == this.currentInterpretationType
            );
            this.switchVoice(2, index1, 0);
          } else {
            this.SoundType = [];
          }
        }
      });
    } else {
      getVoiceDetailInfos({ spotId: ids }).then(async res => {
        // this.$store.commit("setShowVerification", false);
        if (res.code == 0) {
          this.LanguageType = res.datas || [];
          let index = res.datas.findIndex(
            (itm: any) => itm.value == this.currentVoiceType
          );
          await this.switchVoice(1, index, 0);
          if (
            this.LanguageType.length > 0 &&
            this.LanguageType[index].childList &&
            this.LanguageType[index].childList.length > 0
          ) {
            let index1 = this.LanguageType[index].childList.findIndex(
              (itm: any) => itm.value == this.currentInterpretationType
            );
            this.switchVoice(2, index1, 0);
          } else {
            this.SoundType = [];
          }
        }
      });
    }
  }
  //  private currentVoiceType: Number = 0;  // 当前语音播放类型
  //   private currentInterpretationType: Number = 0;  // 当前解说类型
  //   private currentSoundType: Number = 0;  //  当前声音类型
  private switchVoice(types: number, params: number, isfirst: number) {
    if (types === 1) {
      if (this.LanguageType.length > 0) {
        this.$nextTick(() => {
          this.InterpretationType = this.LanguageType[params].childList || [];
          if (isfirst > 0) {
            this.currentInterpretationType = this.InterpretationType[0].value;
          }
          if (this.InterpretationType.length > 0) {
            this.$nextTick(() => {
              this.SoundType = this.InterpretationType[0].childList || [];
              if (isfirst > 0) {
                this.currentSoundType = this.SoundType[0].value;
              }
            });
          } else {
            this.SoundType = [];
          }
        });
      } else {
        this.InterpretationType = [];
      }
    }
    if (types === 2) {
      if (this.InterpretationType.length > 0) {
        this.$nextTick(() => {
          this.SoundType = this.InterpretationType[params].childList || [];
          if (isfirst > 0) {
            this.currentSoundType = this.SoundType[0].value;
          }
        });
      } else {
        this.SoundType = [];
      }
    }
  }
  private getMultiVoice() {
    // 判断是否是多语音
    this.isshowPopover = false;
    getMultiVoice().then((res: any) => {
      if (res.code === 0) {
        this.isshowPopover = res.data.multiSpeech == 1 ? true : false;
        this.$store.commit("setIsShowCommentaryLoca", res.data.speechSource);
      }
    });
  }

  // 判断是否展示客流
  private getCustomShow() {
    this.isAlert = false;
    getServiceConfig().then((res: any) => {
      if (res.code === 0) {
        let platformPrivateAgreeFlag = res.data.platformPrivateAgreeFlag === 1;
        if (platformPrivateAgreeFlag && !utils.getCookie("indexAgreePrivacy")) {
          this.isAlert = true;
        } else {
          this.isAlert = false;
        }
      }
    });
  }
  // 获取地址栏中的token
  private GetQueryString(name: any) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }
  // 改变隐私协议的内容
  changeStatus(val: boolean) {
    console.log("val: ", val);
    this.$nextTick(() => {
      this.loginForm.checkboxGroup = val;
    });
  }
  // 关闭隐私详情
  closedPrivacy(param: boolean) {
    if (param) {
      this.loginForm.checkboxGroup = true;
    }
    this.privacyVisibly = false;
  }
  // 调取隐私详情页
  goPrivacydetail() {
    this.privacyVisibly = true;
  }
  // 首页隐私政策弹框
  @Provide() onSurePrivacy() {
    utils.setCookie("indexAgreePrivacy", 1);
    this.isAlert = false;
  }
  @Provide() onCancelPrivacy() {
    this.isAlert1 = true;
    if (this.privacyTimer) {
      window.clearTimeout(this.privacyTimer);
      this.privacyTimer = null;
    }
    this.privacyTimer = setTimeout(() => {
      this.isAlert1 = false;
    }, 3000);
  }
  // 验证手机号是否合法
  private checkMobile = (value: any) => {
    if (value) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
        value
      );
    }
  };
  // 验证输入的手机号验证码是否和存储的验证码相同
  private checkPhoneCode = (value: any, callback: any) => {};
  @Provide() onSubmit() {
    if (!this.loginForm.tel) {
      this.$toast("请输入手机号");
      return;
    }
    if (!this.loginForm.verificationCode) {
      this.$toast("请输入验证码");
      return;
    }
    if (!this.loginForm.checkboxGroup && this.$store.state.agreePrivacy) {
      this.$toast("请阅读并同意隐私政策");
      return;
    }
    let loginVO: object = {
      code: this.loginForm.verificationCode,
      phone: this.loginForm.tel
    };
    userLogin(loginVO).then(res => {
      //
      if (res.code === 0) {
        this.$store.commit("setShowVerification", false);
        this.$toast("登录成功!");
        this.$store.commit("setUserInfo", res.data);
        // 修改状态为已登录
        this.$store.commit("setLoginState", true);
        //保存用户信息
        localStorage.setItem("phone", res.data.phone);
        localStorage.setItem("nickname", res.data.nickname);
        localStorage.setItem("sex", res.data.sex || "");
        localStorage.setItem("userSn", res.data.userSn);
        this.$store.commit("setUserInfo", res.data);
        // 保存token
        localStorage.setItem("token", res.data.token);
        // 关闭隐私政策外弹框
        this.loginForm.tel = "";
        this.loginForm.verificationCode = "";
        this.loginForm.contenttext = "";
        this.loginForm.timer = "";
        this.loginForm.showloginCode = "";
        this.loginForm.count = "";
        this.loginForm.verification = "";
        this.loginForm.title = "";
        this.loginForm.checkboxGroup = false;
        // 刷新当前页面
        // this.$router.go(0)
        if (this.$store.state.routerPath !== "/scenic-guide") {
          if (this.routerUrl) {
            this.$router.push(this.routerUrl);
          } else {
            this.$router.go(0);
          }
        }
      } else {
        this.$toast(res.message);
      }
    });
  }
  //取消，隐藏验证码弹窗
  @Provide() cancel() {
    this.loginForm.verificationCode = "";
    this.loginForm.tel = "";
    this.$refs.loginFormRef.resetValidation();
    // 清除倒计时
    this.loginForm.showloginCode = true;
    clearInterval(this.loginForm.timer);
    this.loginForm.timer = null;
    this.isabled = false;
    this.loginForm.checkboxGroup = false;
    this.$store.commit("setShowVerification", false);
    if (
      this.$store.state.routerPath !== "/scenic-guide" &&
      this.$store.state.routerPath !== "/scenic-detial-info"
    ) {
      if (
        this.$store.state.routerPath === "/edit-profile" ||
        this.$store.state.routerPath === "/personal-page"
      ) {
        this.$router.push({ path: "/scenic-guide" });
      } else if (this.$store.state.routerPath.indexOf("/activity-detial") < 0) {
        this.$router.go(-1);
      }
    }
    if (this.$store.state.routerPath.indexOf("/guide-album") > -1) {
      this.$router.replace({ path: "/scenic-guide" });
    }
  }
  // 获取手机验证码
  @Provide() getloginPhoneCode(e: any) {
    // 如果未输入手机号，结束执行
    if (!this.loginForm.tel) {
      return;
    } else {
      // 获取验证码之前校验手机号是否输入正确
      if (!this.checkMobile(this.loginForm.tel)) {
        return;
      }
    }
    // 当点击发送验证码后，开始60秒倒计时
    const TIME_COUNT = 60;
    // 调用接口，向手机号发送验证码
    getVerificationCode({ phone: this.loginForm.tel }).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.$toast(`验证码发送成功!`);
          this.isabled = true;
          if (!this.loginForm.timer) {
            this.loginForm.showloginCode = false;
            this.loginForm.count = TIME_COUNT;
            this.loginForm.timer = setInterval(() => {
              if (
                this.loginForm.count > 0 &&
                this.loginForm.count <= TIME_COUNT
              ) {
                this.loginForm.count -= 1;
              } else {
                this.loginForm.showloginCode = true;
                clearInterval(this.loginForm.timer);
                this.loginForm.timer = null;
                this.isabled = false;
              }
            }, 1000);
          }
        } else {
          this.$toast("验证码发送失败!");
          this.loginForm.verification = "重新发送";
          return;
        }
      } else {
        this.$toast(`${res.message}!`);
        return;
      }
    });
  }
  // 验证用户是否登录
  @Provide() verifyLogin(routerUrl: any) {
    if (!localStorage.getItem("phone")) {
      this.routerUrl = routerUrl;
      this.$store.commit("setShowVerification", true);
    } else {
      this.$router.push(routerUrl);
    }
  }
  /* 获取url中参数 */
  @Provide() getQueryVariable(variable: string) {
    return (
      decodeURIComponent(
        // @ts-ignore url解码中文字符
        (new RegExp("[?|&]" + variable + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.href
          // eslint-disable-next-line no-sparse-arrays
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || ""
    );
  }
  // 数字格式化
  @Provide() formatDigital(number: number | string) {
    let result = number;
    if (!number) {
      return result;
    }
    let numberStr = number.toString();
    let pointIndex = numberStr.indexOf(".");
    let lastIndex = numberStr.length - 1;
    let point = numberStr.substr(pointIndex, lastIndex);
    if (pointIndex >= 0) {
      lastIndex = pointIndex - 1;
    }
    result = "";
    let count = 1;
    for (let i = lastIndex; i >= 0; i--, count++) {
      let temp = numberStr[i];
      result = temp + result;
      if (count % 3 === 0 && i !== 0) {
        result = "," + result;
      }
    }
    if (pointIndex >= 0) {
      return result + point;
    } else {
      return result;
    }
  }
  /**
   * 添加收藏
   * resourceId: 资源ID
   * type: 1 收藏   0取消收藏
   */
  @Provide() saveCollection(
    resourceId: number | string,
    type: number | string = 1
  ) {
    this.$store.commit("showOverlay");
    saveCollection({
      openId: localStorage.getItem("openId") || this.$store.state.openId || "",
      resourceId: resourceId,
      type: type
    }).then((res: any) => {
      this.$store.commit("hideOverlay");
      if (res.code === 0) {
        this.$router.push({ path: "/collection" });
        Toast("收藏成功");
      } else {
        Toast(res.message);
      }
    });
  }
  /**
   * 两地之间的距离
   * @param lon1
   * @param lat1
   * @param lon2
   * @param lat2
   */
  @Provide() getDistance(
    lng1: string,
    lat1: string,
    lng2?: string,
    lat2?: string
  ) {
    // 只传一组经纬度时，将第二组经纬度设置为当前定位的位置
    if (!lng2 && !lat2) {
      lng2 = localStorage.getItem("lng") || this.$store.state.lng;
      lat2 = localStorage.getItem("lat") || this.$store.state.lat;
    }
    // 如果经纬度为空
    if (!lng1 || !lat1 || !lng2 || !lat2) {
      return "";
    }
    let p1 = [lng1, lat1];
    let p2 = [parseFloat(lng2), parseFloat(lat2)];
    let dis = AMap.GeometryUtil.distance(p1, p2);
    // @ts-ignore
    return parseFloat(dis / 1000).toFixed(2) + "km";
  }
  @Provide() getDistanceNum(
    lng1: string,
    lat1: string,
    lng2?: string,
    lat2?: string
  ) {
    // 只传一组经纬度时，将第二组经纬度设置为当前定位的位置
    if (!lng2 && !lat2) {
      lng2 = localStorage.getItem("lng") || this.$store.state.lng;
      lat2 = localStorage.getItem("lat") || this.$store.state.lat;
    }
    // 如果经纬度为空
    if (!lng1 || !lat1 || !lng2 || !lat2) {
      return 99999;
    }
    let p1 = [lng1, lat1];
    let p2 = [parseFloat(lng2), parseFloat(lat2)];
    let dis = AMap.GeometryUtil.distance(p1, p2);
    return dis;
  }
  /**
   * 跳转金牌解说购买页面
   * voicePacketId: 语音包id
   */
  private jpjsData = {
    showjpjsPay: false,
    title: "",
    num: 0,
    voicePacketId: ""
  };
  // 确定跳转支付页
  private confirmPay() {
    let voicePacketId = this.jpjsData.voicePacketId;
    let jpjsPayUrl = this.$store.state.jpjsPayUrl || "";
    let notifyUrl = this.$store.state.notifyUrl || "";
    if (
      jpjsPayUrl &&
      notifyUrl &&
      voicePacketId &&
      !this.$store.state.showOverlay
    ) {
      let callbackApi = `${notifyUrl}?ytyOpenId=${localStorage.getItem(
        "userSn"
      )}&vcode=${localStorage.getItem(
        "vcode"
      )}&voicePacketId=${voicePacketId}&token=${localStorage.getItem(
        "token"
      )}&pageUrl=${encodeURIComponent(window.location.href)}`;
      let reUrl = `${jpjsPayUrl}?id=${voicePacketId}&openid=${localStorage.getItem(
        "userSn"
      )}&headimg=${encodeURIComponent(
        localStorage.getItem("avatar") || this.$store.state.userInfo.avatar
      )}&notifyurl=${encodeURIComponent(
        callbackApi
      )}&name=${localStorage.getItem("nickname")}`;
      // alert(reUrl);
      this.$store.commit("showOverlay");
      setTimeout(() => {
        this.$store.commit("hideOverlay");
      }, 1000);
      window.location.href = reUrl;
    }
  }
  // 是否直接点击购买按钮
  private isgotoby = false;
  // 设置对应专辑信息
  @Provide() gotoJpjsPay(data: {
    title: string;
    num: string | number;
    voicePacketId: string | number;
    gotoBuy?: boolean;
  }) {
    if (event) {
      // @ts-ignore
      event.stopPropagation();
    }
    this.isgotoby = data.gotoBuy ? true : false;
    Object.assign(this.jpjsData, data);
    this.jpjsData.showjpjsPay = true;
  }
  /* S 定位 */
  private geolocation: any = {};
  private initLocation() {
    // // 初始化后获取一次定位信息
    // this.getCurrentPosition();
  }
  private initIPLocation() {
    // let geolocation = new qq.maps.Geolocation(
    //   "OQ4BZ-6S7K6-PMCSG-EQM4K-TVCG6-DIBAW",
    //   "mytest"
    // );
    // let _this = this;
    // geolocation.getIpLocation(showPosition, showErr);
    // function showPosition(position: any) {
    //   //  alert(position.lng + "," + position.lat);
    //   _this.$store.commit("setLocationLngLat", {
    //     lng: position.lng,
    //     lat: position.lat
    //   });
    //   // 经纬度也写入到local里面去
    //   localStorage.setItem("lng", position.lng);
    //   localStorage.setItem("lat", position.lat);
    // }
    // function showErr() {
    //
    //   // _this.getCurrentPosition(); //定位失败再请求定位，测试使用
    // }
    let that = this;
    if (/MicroMessenger/i.test(navigator.userAgent)) {
      //判断是否是微信环境
      //微信环境
      wx.miniProgram.getEnv(function(res: any) {
        if (res.miniprogram) {
          // 小程序环境下逻辑
          that.getLngLatSmall();
        } else {
          //非小程序环境下逻辑
          // that.getH5Location() //一进入h5页面就显示用户当前定位，下面的QQ定位有时候不能获取
          that.getLngLat();
        }
      });
    } else if (/AlipayClient/i.test(navigator.userAgent)) {
      that.zfbLocation();
    } else {
      //非微信环境和支付宝环境逻辑
      that.getLngLat();
    }
  }
  private getH5Location() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showH5Position,
        this.showH5Error
      );
    } else {
      alert("浏览器不支持地理定位。");
    }
  }
  private showH5Error(error: any) {}
  private showH5Position(position: any) {
    let lnglat: number[] =
      comm.Convert_BD09_To_GCJ02(
        position.coords.longitude,
        position.coords.latitude
      ) || [];
    localStorage.setItem("lng", String(lnglat[0]));
    localStorage.setItem("lat", String(lnglat[1]));
    this.$store.commit("setLocationLngLat", {
      lng: String(lnglat[0]),
      lat: String(lnglat[1])
    });
  }

  // 支付宝获取经纬度(小程序)
  zfbLocation() {
    let _this = this;
    my.getLocation({
      success(res: any) {
        const latitude = res.latitude;
        const longitude = res.longitude;
        _this.$store.commit("setLocationLngLat", {
          lng: longitude,
          lat: latitude
        });
        // 经纬度也写入到local里面去
        localStorage.setItem("lng", longitude);
        localStorage.setItem("lat", latitude);
        _this.$store.commit("setPositioningComplete", true);
        // alert(latitude+`:定位信息latitude,longitude${longitude}`)
      },
      fail(err: any) {
        if (
          _this.smallLngLat &&
          _this.smallLngLat.length > 0 &&
          _this.smallLngLat[0] &&
          _this.smallLngLat[1]
        ) {
          localStorage.setItem("lng", String(_this.smallLngLat[0]));
          localStorage.setItem("lat", String(_this.smallLngLat[1]));
          _this.$store.commit("setLocationLngLat", {
            lng: String(_this.smallLngLat[0]),
            lat: String(_this.smallLngLat[1])
          });
          _this.$store.commit("setPositioningComplete", true);
        }
      }
    });
  }
  // 获取经纬度(小程序)
  getLngLatSmall() {
    let _this = this;
    wx.getLocation({
      type: "wgs84",
      success(res: any) {
        const latitude = res.latitude;
        const longitude = res.longitude;
        _this.$store.commit("setLocationLngLat", {
          lng: latitude,
          lat: longitude
        });
        // 经纬度也写入到local里面去

        localStorage.setItem("lng", longitude);
        localStorage.setItem("lat", latitude);
        _this.$store.commit("setPositioningComplete", true);
      },
      fail(err: any) {
        if (
          _this.smallLngLat &&
          _this.smallLngLat.length > 0 &&
          _this.smallLngLat[0] &&
          _this.smallLngLat[1]
        ) {
          localStorage.setItem("lng", String(_this.smallLngLat[0]));
          localStorage.setItem("lat", String(_this.smallLngLat[1]));
          _this.$store.commit("setLocationLngLat", {
            lng: String(_this.smallLngLat[0]),
            lat: String(_this.smallLngLat[1])
          });
          _this.$store.commit("setPositioningComplete", true);
        } else {
          _this.getH5Location();
        }
      }
    });
  }
  // 获取经纬度（qq方式）
  getLngLat() {
    // 创建script标签，引入外部文件
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://map.qq.com/api/js?v=2.exp&key=QMQBZ-MJHWJ-CTMF4-KF7TT-4TUH3-P6BBC";
    document.getElementsByTagName("head")[0].appendChild(script);
    let geolocation = new qq.maps.Geolocation(
      "OQ4BZ-6S7K6-PMCSG-EQM4K-TVCG6-DIBAW",
      "mytest"
    );
    let _this = this;
    geolocation.getLocation(showPosition, showErr);
    function showPosition(position: any) {
      _this.$store.commit("setLocationLngLat", {
        lng: position.lng,
        lat: position.lat
      });

      // 经纬度也写入到local里面去
      localStorage.setItem("lng", position.lng);
      localStorage.setItem("lat", position.lat);
      _this.$store.commit("setPositioningComplete", true);
    }
    function showErr() {
      // 定位失败就采用，模糊定位
      AMap.plugin("AMap.CitySearch", function() {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function(status: any, result: any) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息

            AMap.plugin("AMap.Geocoder", function() {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode,
                timeout: 200
              });
              var lnglat = result.rectangle.split(";")[0].split(",");
              geocoder.getAddress(lnglat, function(status: any, data: any) {
                if (status === "complete" && data.info === "OK") {
                  // result为对应的地理位置详细信息
                  console.log(
                    data.regeocode.addressComponent.businessAreas[0].location
                      .lat
                  );
                  const lat =
                    data.regeocode.addressComponent.businessAreas[0].location
                      .lat;
                  const lng =
                    data.regeocode.addressComponent.businessAreas[0].location
                      .lng;

                  localStorage.setItem("lng", lng);
                  localStorage.setItem("lat", lat);
                  _this.$store.commit("setLocationLngLat", {
                    lng: lng,
                    lat: lat
                  });

                  _this.$store.commit("setPositioningComplete", true);
                }
              });
            });
          }
        });
      });
      // alert('定位失败')
      //_this.getCurrentPosition(); //定位失败再请求定位，测试使用
    }
  }
  // 获取定位
  @Provide() getCurrentPosition() {
    let _this = this;
    this.geolocation.getLocation(showPosition, showErr);
    function showPosition(position: any) {
      //  alert(position.lng + "," + position.lat);
      _this.$store.commit("setLocationLngLat", {
        lng: position.lng,
        lat: position.lat
      });
      // 经纬度也写入到local里面去
      localStorage.setItem("lng", position.lng);
      localStorage.setItem("lat", position.lat);
    }
    function showErr() {
      // _this.getCurrentPosition(); //定位失败再请求定位，测试使用
    }
  }
  // 判断是否在景区 返回1 表示未获取到经纬度
  @Provide() private getIndexMapUrl(boundsLimit: any) {
    if (boundsLimit) {
      // 有本地边界时，本地判断
      if (localStorage.getItem("lng") && localStorage.getItem("lat")) {
        if (
          boundsLimit.contains([
            localStorage.getItem("lng"),
            localStorage.getItem("lat")
          ])
        ) {
          localStorage.setItem("inSenic", "true");
        } else {
          localStorage.setItem("inSenic", "false");
        }
      } else {
        if (comm.isWechatBrowsers()) {
          this.$dialog.confirm({
            message: "未获取到定位权限，请重启微信后再尝试",
            showCancelButton: false
          });
        } else {
          this.$dialog.confirm({
            message: "请检查位置服务是否开启",
            showCancelButton: false
          });
        }
        return 1;
      }
    } else {
      // 无本地边界，调用接口获取
      getIndexMapUrl({
        longitude: this.$store.state.lng,
        latitude: this.$store.state.lat
      }).then((res: any) => {
        if (res.code === 0 && res.data.isInScenicScope) {
          // 在景区时
          localStorage.setItem("inSenic", "true");
          try {
            // @ts-ignore
            localStorage.setItem("vcode", res.data.scenicList[0].vcode || "");
            this.$store.commit("setVcode", res.data.scenicList[0].vcode || "");
          } catch (e) {
            localStorage.setItem("inSenic", "false");
          }
        }
      });
    }
  }
  /* E 定位 */
  /* S 线路规划 跳转高德 */
  @Provide() private getPathLine(
    lng: string | number,
    lat: string | number,
    name: string
  ) {
    let _this = this;
    if (localStorage.getItem("lng")) {
      this.$dialog
        .confirm({
          message: "是否导航至目的地？",
          confirmButtonText: "导航",
          closeOnClickOverlay: true
        })
        .then(() => {
          if (/MicroMessenger/i.test(navigator.userAgent)) {
            //判断是否是微信环境
            //微信环境
            wx.miniProgram.getEnv(function(res: any) {
              if (res.miniprogram) {
                let paths = _this.$store.state.saveRouterPath;
                wx.miniProgram.navigateTo({
                  url:
                    "/pages/daohang/daohang?latitude=" +
                    lat +
                    "&longitude=" +
                    lng +
                    "&shopname=" +
                    name +
                    "&pageUrl=/" +
                    paths
                });
                return false;
              } else {
                _this.goGaode(lng, lat, name);
              }
            });
          } else if (/AlipayClient/i.test(navigator.userAgent)) {
            my.openLocation({
              longitude: lng,
              latitude: lat,
              name: name,
              address: name
            });
            // let paths = _this.$store.state.saveRouterPath;
            // my.navigateTo({
            //   url:
            //     "/pages/daohang/daohang?latitude=" +
            //     lat +
            //     "&longitude=" +
            //     lng +
            //     "&shopname=" +
            //     name +
            //     "&pageUrl=/" +
            //     paths
            // });
            // return false;
          } else {
            _this.goGaode(lng, lat, name);
          }
        })
        .catch(() => {});
    } else {
      this.$dialog
        .confirm({
          message: "未获取到定位，是否重新定位？",
          confirmButtonText: "确定",
          closeOnClickOverlay: true
        })
        .then(() => {
          this.initIPLocation();
        });
    }
  }
  /* E 线路规划 跳转高德 */
  private goGaode(lng: string | number, lat: string | number, name: string) {
    console.log("mapInfo: ");
    this.$store.commit("showOverlay");
    setTimeout(() => {
      this.$store.commit("hideOverlay");
    }, 500);
    // v2.0 脱离微信
    // if (comm.isWechatBrowsers()) {
    //
    //   wx.openLocation({
    //     latitude: parseFloat(`${lat}`), // 纬度，浮点数，范围为90 ~ -90
    //     longitude: parseFloat(`${lng}`), // 经度，浮点数，范围为180 ~ -180。
    //     name: name, // 位置名
    //     address: "", // 地址详情说明
    //     scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
    //     infoUrl: location.href // 在查看位置界面底部显示的超链接,可点击跳转
    //   });
    // } else {
    // }
    // 高德地图导航
    const mapInfo: any =
      (sessionStorage.getItem("mapInfo") &&
        JSON.parse(sessionStorage.getItem("mapInfo") || "")) ||
      "";
    console.log("mapInfo: ", mapInfo);
    let url;
    if ((mapInfo && mapInfo.gpsMap === 0) || !mapInfo.gpsMap) {
      // 高德地图导航
      url = `https://m.amap.com/navigation/carmap/saddr=&daddr=${`${lng}`},${lat},${name ||
        "目的地"}&src=nyx_super`;
    } else {
      // 百度地图导航
      // 当前位置的经纬度
      const currentLng = this.$store.state.lng;
      const currentLat = this.$store.state.lat;
      url = `http://api.map.baidu.com/direction?origin=latlng:${currentLat},${currentLng}|name:我的位置&destination=${lat},${lng}&mode=driving&region=''&output=html&src=webapp.baidu.onePictureTour`;
    }
    window.location.href = decodeURIComponent(url);
  }
  /* S 图片预览 */
  private imagePreview = {
    index: 1,
    images: [] as Array<string>
  };
  // 图片切换时
  private imagePreviewOnChange(index: number) {
    this.imagePreview.index = index + 1;
  }
  // 点击查看图片
  @Provide() openPreview(list: Array<string>) {
    this.$store.commit("setShowimagePreview", true);
    this.imagePreview.images = list;
  }
  /* E 图片预览 */
  // 获取微信配置
  private wxsdkSign: any = "";
  private singError = "false"; // 微信授权失败 与否
  private smallLngLat: number[] = [];
  private getJsInfo() {
    let _this = this;

    if (
      window.location.hash &&
      window.location.hash.includes("?") &&
      window.location.hash.includes("&")
    ) {
      let strs = window.location.hash.split("?")[1];
      let arr = strs.split("&");
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] && arr[i].includes("=")) {
          let num: any = Number(arr[i].split("=")[1]);
          _this.smallLngLat.push(num);
        }
      }
    }
    if (comm.isWechatBrowsers() && !this.wxsdkSign) {
      _this.jsLocation(0);
    }
    if (/MicroMessenger/i.test(navigator.userAgent)) {
      //判断是否是微信环境
      //微信环境
      wx.miniProgram.getEnv(function(res: any) {
        if (res.miniprogram) {
          _this.jsLocation(1);
        }
      });
    }
  }
  // 重置语音
  resetChooseVoice() {
    this.currentVoiceType = this.LanguageType[0].value;
    this.InterpretationType = this.LanguageType[0].childList || [];
    this.currentInterpretationType = this.LanguageType[0].childList[0].value;
    this.SoundType = this.LanguageType[0].childList[0].childList || [];
    this.currentSoundType = this.LanguageType[0].childList[0].childList[0].value;
    // this.sureChooseVoice();
  }
  // 提交语音选择
  sureChooseVoice() {
    let obj = {
      languageType: this.currentVoiceType,
      quarterType: this.currentInterpretationType,
      voiceType: this.currentSoundType
    };
    this.$store.commit("setCurrentLangageType", obj);
    this.showPopover = false;
  }
  // 选择语音
  private setVoiceType(param: Number, typs: Number, index: number) {
    if (typs === 0) {
      this.currentVoiceType = param || 0;
      this.switchVoice(1, index, 1);
      this.SoundType = [];
      this.InterpretationType = [];
      // this.currentSoundType = -1;
      // this.currentInterpretationType = -1;
    }
    if (typs === 1) {
      this.currentInterpretationType = param || 0;
      this.switchVoice(2, index, 1);
      // this.currentSoundType = -1;
    }
    if (typs === 2) {
      this.currentSoundType = param || 0;
    }
  }
  // 小程序定位
  private jsLocation(param: any) {
    let _this = this;
    // if (comm.isWechatBrowsers() && !this.wxsdkSign) {
    getJsInfo({
      url: window.location.href.split("#")[0],
      error: this.singError
    }).then((res: any) => {
      // 成功时
      if (res.code === 0 && res.data) {
        let sdkSign = res.data; // 后台返回的值
        this.wxsdkSign = res.data;
        wx.config({
          debug: false, // 因为在手机上测试没法打印，当debug为true时，所有的返回值都会在手机上alert出来
          appId: sdkSign.appId, // 必填，公众号唯一标识
          timestamp: sdkSign.timestamp, // 必填，生成签名的时间戳
          nonceStr: sdkSign.nonceStr, // 必填，生成签名的随机串
          signature: sdkSign.signature, // 必填，签名
          jsApiList: [
            "getLocation",
            "openLocation",
            "onMenuShareTimeline",
            "onMenuShareAppMessage"
          ] // 必填，需要使用的JS接口列表，需要用到什么接口就去开发者文档查看相应的字段名
        });
        wx.ready(function() {
          wx.getLocation({
            type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function(res: any) {
              //
              let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              _this.$store.commit("setLocationLngLat", {
                lng: latitude,
                lat: longitude
              });
              // 经纬度也写入到local里面去
              localStorage.setItem("lng", longitude);
              localStorage.setItem("lat", latitude);
              //
            },
            fail: (res: any) => {
              _this.$dialog.confirm({
                message: "请检查位置服务是否开启，或重启微信尝试",
                showCancelButton: false
              });
            }
          });
          if (sdkSign.share) {
            let _url = window.location.href;
            let url = _url.indexOf("?") !== -1 ? _url.split("?")[0] : _url;
            let img = sdkSign.share.imgUrl;
            let desc = sdkSign.share.desc;
            let shareTitle = sdkSign.share.title;
            wx.onMenuShareTimeline({
              title: shareTitle, // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              success: function() {
                // 用户点击了分享后执行的回调函数
              }
            });
            wx.onMenuShareAppMessage({
              title: shareTitle, // 分享标题
              desc: desc, // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              success: function() {}
            });
          }
        });
        // 失败以后重新调用 授权方法 ,并控制只调用一次
        wx.error(function(res: any) {
          _this.wxsdkSign = "";

          if (_this.singError === "false") {
            _this.singError = "true";
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            _this.getJsInfo();
          }
        });
      }
    });
    // }
  }

  /* S 播放音乐 */
  private playStatus = false; // 是否正在播放
  private bagData: any = "";
  // 播放音频
  @Provide() playJPJSVoice(url: string, data: bagData) {
    this.bagData = data;
    // @ts-ignore
    if (data.isbuy || Number(data.price) === 0 || data.localFlag) {
      // 清空定时器
      this.clearAudioTimer();
      // 重复点击同一个音频  则停止播放
      if (this.$store.state.audioData.playUrl === url) {
        if (this.$store.state.audioData.playStatus) {
          this.pauseAudio();
        } else {
          // @ts-ignore
          this.$refs.audio.play();
          this.$store.commit("setAudioPlayStatus", true);
          this.$store.commit("setTryStatus", false);
        }
      } else {
        this.$store.commit("setAudioData", { playUrl: url, title: data.title });
        this.timeoutPlay();
        this.$store.commit("setTryStatus", false);
        // 提交播放记录
        // @ts-ignore
        if (!data.localFlag || data.localFlag !== 1) {
          submitListenRecord({
            id: data.voicePacketId,
            openId: localStorage.getItem("openId")
          });
          // 设置专辑ID
          this.$store.commit("setAudioBagID", data.voicePacketId || "");
        }
      }
    } else {
      // 未购买则跳转购买链接
      this.gotoJpjsPay({
        title: data.title,
        num: data.num,
        voicePacketId: data.voicePacketId
      });
    }
  }
  // 播放 试听音频
  private tryaudioTimer: any = 0;
  private tryTimeout: any = 0; // 试听时长定时器
  @Provide() tryplayJPJSVoice(url: string, data: bagData) {
    this.$store.commit("setTryStatus", true);
    this.bagData = data;
    // 清空定时器
    this.clearAudioTimer();
    this.$store.commit("setAudioData", { playUrl: url, title: data.title });
    let _this = this;
    this.tryaudioTimer = setTimeout(() => {
      let audio = document.getElementById("audio");
      // @ts-ignore
      audio.play();
      _this.$store.commit("setAudioPlayStatus", true);
      _this.tryTimeout = setTimeout(() => {
        // 试听结束后 弹出购买提示
        _this.gotoJpjsPay({
          title: data.title,
          num: data.num,
          voicePacketId: data.voicePacketId
        });
        // 停止播放
        _this.stopAudio();
      }, 30 * 1000);
      // @ts-ignore 试听播放完成后关闭定时器 并弹出购买提示
      audio.onended = () => {
        _this.stopAudio();
        // 试听结束后 弹出购买提示
        _this.gotoJpjsPay({
          title: data.title,
          num: data.num,
          voicePacketId: data.voicePacketId
        });
      };
    }, 100);
    // 提交播放记录
    submitListenRecord({
      id: data.voicePacketId,
      type: 1,
      openId: localStorage.getItem("openId")
    });
    // 设置专辑ID
    this.$store.commit("setAudioBagID", data.voicePacketId || "");
  }
  // 延迟触发一个播放事件，不然移动端有的会无法播放
  private audioTimer: any = 0;
  private timeoutPlay() {
    let _this = this;
    this.clearAudioTimer();
    if (this.$store.state.audioData.tryPlay) {
      // 若为试听时触发播放 跳转支付
      this.gotoJpjsPay({
        title: this.bagData.title,
        num: this.bagData.num,
        voicePacketId: this.bagData.voicePacketId
      });
    } else {
      // if (
      //   this.$store.state.saveRouterPath === "line" &&
      //   this.lineVoiceData.length === 0
      // ) {
      //   this.getLineVoiceData();
      //   return false;
      // }
      this.audioTimer = setTimeout(() => {
        let audio = this.$refs.audio;
        // @ts-ignore
        audio.play();
        // @ts-ignore 播放完成
        audio.onended = () => {
          _this.stopAudio();
        };
        this.$store.commit("setAudioPlayStatus", true);
        this.isplayorpause = false;
      }, 100);
    }
  }
  @Provide() pauseAudio() {
    // @ts-ignore
    if (
      this.$store.state.saveRouterPath === "line" &&
      this.lineVoiceData.length === 0
    ) {
      this.getLineVoiceData();
      return false;
    }

    this.$refs.audio.pause();
    // 清空定时器
    this.clearAudioTimer();
    this.$store.commit("setAudioPlayStatus", false);
    this.isplayorpause = true;
  }
  // 停止播放
  @Provide() stopAudio() {
    this.clearAudioTimer();
    // @ts-ignore
    this.$refs.audio.currentTime = 0;
    // @ts-ignore
    this.$refs.audio.pause();
    this.$store.commit("setAudioPlayStatus", false);
  }
  // 下一首
  private nextAudio() {
    // 专辑列表多于一条时
    if (this.$store.state.audioData.list.length > 1) {
      if (this.$store.state.audioData.tryPlay) {
        // 若为试听时触发播放 跳转支付
        this.gotoJpjsPay({
          title: this.bagData.title,
          num: this.bagData.num,
          voicePacketId: this.bagData.voicePacketId
        });
      } else {
        // 先重新排列
        this.reSortLst(this.$store.state.audioData.list);
        // 设置播放信息
        let data = this.$store.state.audioData.list[1];
        this.$store.commit("setAudioData", {
          playUrl: data.url,
          title: data.spotname
        });
        this.timeoutPlay();
      }
    }
  }
  // 上一首
  private prveAudio() {
    // 专辑列表多于一条时
    if (this.$store.state.audioData.list.length > 1) {
      if (this.$store.state.audioData.tryPlay) {
        // 若为试听时触发播放 跳转支付
        this.gotoJpjsPay({
          title: this.bagData.title,
          num: this.bagData.num,
          voicePacketId: this.bagData.voicePacketId
        });
      } else {
        // 先重新排列
        this.reSortLst(this.$store.state.audioData.list);
        // 设置播放信息
        let list = this.$store.state.audioData.list;
        // 上一首时  将列表最后一条重新拉回数组第一条
        let data = list[list.length - 1];
        this.$store.commit("setAudioData", {
          playUrl: data.url,
          title: data.spotname
        });
        this.timeoutPlay();
      }
    }
  }
  // 将当前播放的专辑列表重新排序
  private reSortLst(list: Array<any>) {
    let newList: Array<any> = [],
      _this = this;
    let currIndex = 0; // 在列表中查找到当前播放的音频的索引
    list.forEach((item: any, index: number) => {
      // 当前音频索引
      if (_this.$store.state.audioData.title == item.spotname) {
        currIndex = index;
      }
    });
    newList = list.slice(currIndex).concat(list.slice(0, currIndex));
    this.$store.commit("setAudioList", newList);
    return newList;
  }
  // 清除音频相关定时器
  private clearAudioTimer() {
    clearTimeout(this.tryaudioTimer);
    clearTimeout(this.audioTimer);
    clearTimeout(this.tryTimeout);
  }
  // 当播放专辑ID变化时  获取专辑对应的解说列表 ; 试听不触发此方法
  @Watch("$store.state.audioData.bagID")
  getNewAudioList(id: number | string) {
    this.getBagDetail(id);
  }
  // 监听 是否刷新经纬度
  @Watch("$store.state.getPosition")
  updateMyposition(val: boolean) {
    if (val) {
      this.initIPLocation();
      this.initLocation();
      // this.$router.go(0);
    }
  }
  // 监听路由变化
  @Watch("$store.state.saveRouterPath")
  updateTypes(val: string) {
    this.routePoint = {};
    this.isPlayVoice = false;
    this.stopAudio();
    if (val && val === "line") {
      this.getLineData();
    } else {
      this.isPlayVoice = false;
    }
  }
  private getLineData() {
    this.voiceLists = [];
    this.isPlayVoice = false;
    this.lineVoiceData = [];
    this.stopAudio();
    if (!this.routePoint.id) {
      return false;
    }
    if (!this.routePoint.hasVoice) {
      this.isPlayVoice = false;
      return false;
    }
    let languageTypes = "";
    if (this.$store.state.currentLangageType.languageType >= 0) {
      languageTypes = this.$store.state.currentLangageType.languageType;
    }
    let quarterTypes = "";
    if (this.$store.state.currentLangageType.quarterType >= 0) {
      quarterTypes = this.$store.state.currentLangageType.quarterType;
    }
    let voiceTypes = "";
    if (this.$store.state.currentLangageType.voiceType >= 0) {
      voiceTypes = this.$store.state.currentLangageType.voiceType;
    }
    getLineVoiceData({
      currPage: 1,
      pageSize: 50,
      languageType: languageTypes,
      quarterType: quarterTypes,
      voiceType: voiceTypes,
      routeId: this.routePoint.id || ""
    }).then((res: any) => {
      if (res.code === 0) {
        this.voiceLists = res.datas || [];
        this.isPlayVoice = true;
        const list = res.datas;
        this.lineVoiceData = list || [];
        if (list.length > 0 && list[0].localFlag == 1) {
          this.$store.commit("setShowVerification", false);
          list.length > 1
            ? (this.hasMultipleVoice = true)
            : (this.hasMultipleVoice = false);
          this.recylePlay(list);
          return false;
        }
        let hasFreeVoice = false;
        if (list.length > 0) {
          // 默认播放第一条音频
          let defaultAudioIndex = 0;
          for (let i = 0; i < list.length; i++) {
            // @ts-ignore
            if (list[i].isBuy === 1 || Number(list[i].price) === 0) {
              defaultAudioIndex = i;
              hasFreeVoice = true;
              break;
            }
          }
          // 有免费语音，或者有用户已经购买的语音
          if (hasFreeVoice) {
            // @ts-ignore
            this.currentVoice = list[defaultAudioIndex];
            // @ts-ignore
            // this.$refs.audio.src = list[defaultAudioIndex].voiceUrl;
            // @ts-ignore
            this.voicePlayStatus = true;
            // this.$refs.audio.play();
          } else {
            this.currentVoice = list[0];
            // @ts-ignore
            // this.$refs.audio.src = list[0].voiceUrl;
            this.voicePlayStatus = true;
          }
          // @ts-ignore
          this.$refs.audio.src = this.currentVoice.voiceUrl;
          this.voicePlayStatus = true;
          // @ts-ignore
          this.currentVoiceType =
            this.currentVoice.languageType >= 0
              ? this.currentVoice.languageType
              : "";
          // @ts-ignore
          this.currentInterpretationType =
            this.currentVoice.quarterType >= 0
              ? this.currentVoice.quarterType
              : "";
          // @ts-ignore
          this.currentSoundType =
            this.currentVoice.voiceType >= 0 ? this.currentVoice.voiceType : "";
          this.hasMultipleVoice = false;
        }
      }
    });
  }
  // 获取语音包详情
  @Provide() private getBagDetail(id: number | string) {
    getBagDetail({
      id: id || "",
      openId: localStorage.getItem("openId")
    }).then((res: any) => {
      // 生成专辑列表
      this.$store.commit("setAudioList", this.getVioceList(res.data.voice));
      // 设置专辑图片
      this.$store.commit("setAudioImg", res.data.bag.headimg || "");
    });
  }
  // 重新遍历并生成解说语音列表
  private getVioceList(voiceList: Array<any>) {
    let list: Array<any> = [],
      _this = this;
    voiceList.forEach((item: any) => {
      list.push({
        spotname: item.name,
        url: item.url,
        id: item.id
      });
    });
    return list;
  }
  private panActive = false; // 是否展开音乐播放面板
  private startY = 0; // 开始移动的位置
  private moveY = 0; // 移动的距离
  private touchStart(e: any) {
    const ev = e || event;
    if (ev.touches.length) {
      // @ts-ignore
      this.$refs.jpjsAudio.style.top = `${this.$refs.jpjsAudio.offsetTop}px`;
      // @ts-ignore
      this.$refs.jpjsAudio.style.bottom = "unset";
      // @ts-ignore
      this.$refs.jpjsAudio.style.transition = "unset";
      this.startY = ev.touches[0].clientY;
    }
  }
  private touchMove(e: any) {
    const ev = e || event;
    if (ev.touches.length) {
      this.moveY = ev.touches[0].clientY - this.startY;
      this.startY = ev.touches[0].clientY;
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.jpjsAudio.style.top = `${parseInt(this.$refs.jpjsAudio.style.top) + this.moveY}px`;
    }
  }
  private touchEnd(e: any) {
    const ev = e || event;
    // @ts-ignore
    this.$refs.jpjsAudio.style.transition = "all 0.6s ease";
  }
  /* E 播放音乐 */
  /* S 计算当前位置在哪个景点 */
  @Watch("$store.state.routeArrow")
  getArrowData() {
    this.getRouteArrowData(this.$store.state.routeArrowData.nowIndex);
  }
  private computeInSecnic() {
    // 在景区范围内才计算，否则不计算
    if (localStorage.getItem("inSenic") === "true") {
      let pointList = this.$store.state.routePathPoint.spotList;
      if (pointList && pointList.length) {
        for (let i = 0, len = pointList.length; i < len; i++) {
          let data = pointList[i];
          if (this.getDistanceNum(data.longitude, data.latitude) < data.scope) {
            this.saveTouristStay(data.id);
            // this.socket.emit('onlineEvent',{
            //     code:201,
            //     data:data.id
            // })
            this.getRouteArrowData(i);
            break;
          }
        }
      }
    } else {
      this.$store.state.routeArrowData.show = false;
    }
  }
  // 获取当前索引的上下景点数据
  private getRouteArrowData(i: number) {
    this.setLBSimg(i);
    let pointList = this.$store.state.routePathPoint.pointList;
    let data = pointList[i];
    let arrowData = {
      show: false,
      showTo: "",
      nowIndex: i,
      nowID: data.pointId,
      nowName: data.pointName,
      prevID: "",
      prevName: "",
      nextID: "",
      nextName: ""
    };
    // 不为第一个点
    if (pointList[i - 1]) {
      arrowData.prevID = pointList[i - 1].pointId;
      arrowData.prevName = pointList[i - 1].pointName;
    }
    // 不为最后一个点
    if (pointList[i + 1]) {
      arrowData.nextID = pointList[i + 1].pointId;
      arrowData.nextName = pointList[i + 1].pointName;
    }
    if (this.$store.state.routeArrow && pointList[i + 1]) {
      // 正向行走，且下一个点存在；
      arrowData.show = true;
      arrowData.showTo = pointList[i + 1].pointName;
    } else if (!this.$store.state.routeArrow && pointList[i - 1]) {
      // 逆向行走，上一个点存在
      arrowData.show = true;
      arrowData.showTo = pointList[i - 1].pointName;
    }
    this.$store.commit("setRouteArrowData", arrowData);
  }
  // 设置当前lbs图
  private setLBSimg(i: number) {
    // @ts-ignore
    let map = window.mapObject;
    // 根据当前方向获取需要的数据
    let routData = this.$store.state.routePathPoint;
    let imgList = this.$store.state.routeArrow
      ? routData.lbsForwardImgs
      : routData.lbsReverseImgs;
    if (map && imgList && imgList.length && i < routData.pointList.length - 1) {
      this.$store.state.routeArrow ? "" : i--;
      // @ts-ignore 手绘图
      if (window.arrowImgLayer) {
        // @ts-ignore
        window.arrowImgLayer.setImageUrl(imgList[i]);
      } else {
        // @ts-ignore
        window.arrowImgLayer = new AMap.ImageLayer({
          url: imgList[i],
          bounds: new AMap.Bounds(
            [routData.mapboundsLeft, routData.mapboundsBottom],
            [routData.mapboundsRight, routData.mapboundsTop]
          ),
          zIndex: 108,
          zooms: [12, 20],
          map: map
        });
      }
    }
  }
  // 上传所在景点
  private saveTouristStay(id: number | string) {
    saveTouristStay({
      openId: localStorage.getItem("openId") || this.$store.state.openId,
      spotId: id
    }).then((res: any) => {});
  }
  private animatArrowImg() {
    setInterval(() => {
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      let layer = window.arrowImgLayer, map = window.mapObject;
      if (layer && map && this.$store.state.routeArrowData.show) {
        layer.hide();
        setTimeout(() => {
          layer.show();
        }, 700);
      }
    }, 1400);
  }
  /* E 计算当前位置在哪个景点 */
  // 控制播放时，点击讲解的文字颜色，是否显示小窗
  private isPlayVoice: Boolean = false;
  // 当前播放的金牌解说信息
  private currentVoice: any = {};
  // 当前语音的播放状态
  private voicePlayStatus = false;
  private hasMultipleVoice = false;
  private activePoint = {};
  // 监听音乐播放状态
  @Watch("voicePlayStatus")
  updateVoicePlayStatus(status: Boolean) {
    // @ts-ignore
    status
      ? (this.$refs.audio.play(),
        (this.isPlayVoice = true),
        this.bus.$emit("setPlayStatus", this.isPlayVoice))
      : this.$refs.audio.pause();
    this.setScenicMarkerIcon(false, this.activePoint);
  }
  @Watch("$store.state.currentLangageType")
  updateVoiceDataType(status: Number) {
    this.$refs.audio.src = "";
    if (this.$store.state.saveRouterPath === "line") {
      this.getRouteVoiceData(this.activePoint);
    } else if (this.$store.state.saveRouterPath === "flowerspot") {
      this.getFlowerSpotVoiceData(this.activePoint);
    } else {
      this.getSpotVoiceData(this.activePoint);
    }
  }
  // 播放观花点语音讲解
  private getFlowerSpotVoiceData(point: Object) {
    this.voicePlayStatus = false;
    this.currentVoice = {};
    this.$refs.audio.src = "";
    this.$refs.audio.pause();
    this.$store.commit("setCurrentCommentarySpot", {
      // @ts-ignore
      flowerId: point.id,
      // @ts-ignore
      jspsCode: point.jpjsCode
    });
    let languageTypes = "";
    if (this.$store.state.currentLangageType.languageType >= 0) {
      languageTypes = this.$store.state.currentLangageType.languageType;
    }
    let quarterTypes = "";
    if (this.$store.state.currentLangageType.quarterType >= 0) {
      quarterTypes = this.$store.state.currentLangageType.quarterType;
    }
    let voiceTypes = "";
    if (this.$store.state.currentLangageType.voiceType >= 0) {
      voiceTypes = this.$store.state.currentLangageType.voiceType;
    }
    getFlowerSpotVoiceData({
      // @ts-ignore
      flowerSpotId: point.id || "",
      currPage: 1,
      pageSize: 100,
      // @ts-ignore
      jpjsCode: point.jpjsCode,
      languageType: languageTypes,
      quarterType: quarterTypes,
      voiceType: voiceTypes
    }).then((res: any) => {
      if (res.code === 0) {
        //
        // 模拟免费
        // res.datas[1].price = 0;
        // 播放列表数据
        const list = res.datas || [];
        if (list.length > 0 && list[0].localFlag == 1) {
          this.$store.commit("setShowVerification", false);
          list.length > 1
            ? (this.hasMultipleVoice = true)
            : (this.hasMultipleVoice = false);
          this.recylePlay(list);
          return false;
        }
        let hasFreeVoice = false;
        let isLocalFlag = 0;
        if (list.length > 0) {
          // 默认播放第一条音频
          isLocalFlag = list[0].localFlag;
          let defaultAudioIndex = 0;
          for (let i = 0; i < list.length; i++) {
            // @ts-ignore
            if (
              list[i].isBuy === 1 ||
              Number(list[i].price) === 0 ||
              list[i].localFlag == 1
            ) {
              defaultAudioIndex = i;
              hasFreeVoice = true;
              break;
            }
          }
          // 没有免费语音且未登录，提示登陆
          if (
            !hasFreeVoice &&
            !this.$store.state.loginState &&
            list[0].localFlag == 0
          ) {
            this.$store.commit("setShowVerification", false);
          }
          // 没有免费语音且用户已登录，跳转金牌解说列表
          if (!hasFreeVoice && this.$store.state.loginState) {
            this.$router.push({
              path: "/jpjs-page",
              query: {
                // @ts-ignore
                senicId: point.jpjsCode,
                // @ts-ignore
                senicName: point.name,
                // @ts-ignore
                spotId: point.id
              }
            });
          }
          // 有免费语音，或者有用户已经购买的语音
          if (hasFreeVoice) {
            // @ts-ignore
            this.currentVoice = list[defaultAudioIndex];
            // @ts-ignore
            this.$refs.audio.src = list[defaultAudioIndex].voiceUrl;
            // @ts-ignore
            this.voicePlayStatus = true;
            this.currentVoiceType = list[defaultAudioIndex].languageType || 0;
            this.currentInterpretationType =
              list[defaultAudioIndex].quarterType || 0;
            this.currentSoundType = list[defaultAudioIndex].voiceType || 0;
            // this.$refs.audio.play();
          }
          list.length > 1
            ? (this.hasMultipleVoice = true)
            : (this.hasMultipleVoice = false);
          // this.playVoice(this.list[defaultAudioIndex])
        }
        // 设置金牌解说支付地址url 暂未用
        // this.$store.commit("setJpjsPayUrl", res.extend);
      }
    });
  }
  // 播放景点语音讲解
  private getSpotVoiceData(point: Object) {
    this.routePoint.currrentPlayStatus = "";
    console.log("point: ", point);
    this.voicePlayStatus = false;
    this.currentVoice = {};
    this.$refs.audio.src = "";
    this.$refs.audio.pause();
    this.$store.commit("setCurrentCommentarySpot", {
      // @ts-ignore
      spotId: point.id,
      // @ts-ignore
      jspsCode: point.jpjsCode
    });
    let languageTypes = "";
    if (this.$store.state.currentLangageType.languageType >= 0) {
      languageTypes = this.$store.state.currentLangageType.languageType;
    }
    let quarterTypes = "";
    if (this.$store.state.currentLangageType.quarterType >= 0) {
      quarterTypes = this.$store.state.currentLangageType.quarterType;
    }
    let voiceTypes = "";
    if (this.$store.state.currentLangageType.voiceType >= 0) {
      voiceTypes = this.$store.state.currentLangageType.voiceType;
    }
    getSpotVoiceData({
      // @ts-ignore
      spotId: point.id || "",
      currPage: 1,
      pageSize: 100,
      // @ts-ignore
      jpjsCode: point.jpjsCode,
      languageType: languageTypes,
      quarterType: quarterTypes,
      voiceType: voiceTypes
    }).then((res: any) => {
      if (res.code === 0) {
        //
        // 模拟免费
        // res.datas[1].price = 0;
        // 播放列表数据
        const list = res.datas || [];
        if (list.length > 0 && list[0].localFlag == 1) {
          this.$store.commit("setShowVerification", false);
          list.length > 1
            ? (this.hasMultipleVoice = true)
            : (this.hasMultipleVoice = false);
          this.recylePlay(list);
          return false;
        }
        let hasFreeVoice = false;
        let isLocalFlag = 0;
        if (list.length > 0) {
          // 默认播放第一条音频
          isLocalFlag = list[0].localFlag;
          let defaultAudioIndex = 0;
          for (let i = 0; i < list.length; i++) {
            // @ts-ignore
            if (
              list[i].isBuy === 1 ||
              Number(list[i].price) === 0 ||
              list[i].localFlag == 1
            ) {
              defaultAudioIndex = i;
              hasFreeVoice = true;
              break;
            }
          }
          // 没有免费语音且未登录，提示登陆
          if (
            !hasFreeVoice &&
            !this.$store.state.loginState &&
            list[0].localFlag == 0
          ) {
            this.$store.commit("setShowVerification", true);
          }
          // 没有免费语音且用户已登录，跳转金牌解说列表
          if (!hasFreeVoice && this.$store.state.loginState) {
            this.$router.push({
              path: "/jpjs-page",
              query: {
                // @ts-ignore
                senicId: point.jpjsCode,
                // @ts-ignore
                senicName: point.name,
                // @ts-ignore
                spotId: point.id
              }
            });
          }

          // 有免费语音，或者有用户已经购买的语音
          if (hasFreeVoice) {
            // @ts-ignore
            this.currentVoice = list[defaultAudioIndex];
            // @ts-ignore
            this.$refs.audio.src = list[defaultAudioIndex].voiceUrl;
            // @ts-ignore
            this.voicePlayStatus = true;
            this.currentVoiceType = list[defaultAudioIndex].languageType || 0;
            this.currentInterpretationType =
              list[defaultAudioIndex].quarterType || 0;
            this.currentSoundType = list[defaultAudioIndex].voiceType || 0;
            // this.$refs.audio.play();
          }
          list.length > 1
            ? (this.hasMultipleVoice = true)
            : (this.hasMultipleVoice = false);
          // this.playVoice(this.list[defaultAudioIndex])
        }
        // 设置金牌解说支付地址url 暂未用
        // this.$store.commit("setJpjsPayUrl", res.extend);
      }
    });
  }
  // 循环播放
  private recylePlay(lists: []) {
    if (lists.length > 0) {
      // @ts-ignore
      let types = lists[0].languageType;
      // @ts-ignore
      let list = lists.filter(itm => itm.languageType === types);

      if (list && list.length > 0) {
        let i = 0;
        // @ts-ignore
        this.playsVoices(i, list);
        let that = this;
        this.$refs.audio.addEventListener(
          "ended",
          function() {
            setTimeout(() => {
              i++;
              // @ts-ignore
              that.playsVoices(i, list);
            }, 200);
          },
          false
        );
      }
    }
  }
  private playsVoices(i: number, list: []) {
    if (i >= list.length) {
      return false;
    }
    this.currentVoice = list[i];
    this.stopAudio();
    // this.$refs.audio.load();
    // @ts-ignore
    this.$refs.audio.src = list[i].voiceUrl;
    // // @ts-ignore
    // this.voicePlayStatus = true;
    // @ts-ignore
    this.currentVoiceType = list[i].languageType || 0;
    // @ts-ignore
    this.currentInterpretationType = list[i].quarterType || 0;
    // @ts-ignore
    this.currentSoundType = list[i].voiceType || 0;
    // this.$refs.audio.play();
    // this.$store.state.saveRouterPath === "line" &&
    console.log(this.routePoint.currrentPlayStatus, "2818");
    if (!this.routePoint.currrentPlayStatus) {
      this.voicePlayStatus = true;
      this.$refs.audio.play();
    } else {
      this.voicePlayStatus = false;
      // this.$refs.audio.play();
    }

    // if (!this.$route.query.senicId) {
    //
    //   this.voicePlayStatus = false;
    //   this.$refs.audio.pause();
    // } else {
    //   // @ts-ignore
    //
    //   this.voicePlayStatus = true;
    //   this.$refs.audio.play();
    // }
    this.isPlayVoice = true;
  }
  // 播放线路语音讲解
  private getRouteVoiceData(point: any) {
    this.$store.commit("setVrAddress", point.vrUrl || "");

    this.routePoint = point;
    console.log(" this.routePoint: ", this.routePoint);
    this.lineVoiceData = [];
    this.getLineData();
    return false;
  }
  // 获取线路的详情或者个人数据时
  private getLineVoiceData() {
    let point = this.routePoint || {};
    if (!point.jpjsCode) {
      this.stopAudio();
      // 已和产品沟通注释掉
      // this.$toast("请配置线路 “" + point.name + "” 的语音解说码");
      return false;
    }
    this.voicePlayStatus = false;
    getRouteVoiceData({
      // @ts-ignore
      jpjsCode: point.jpjsCode,
      currPage: 1,
      pageSize: 100
    }).then((res: any) => {
      if (res.code === 0) {
        //
        // 模拟免费
        res.datas[1].price = 0;
        // 播放列表数据
        const list = res.datas;
        let hasFreeVoice = false;
        if (list.length > 0) {
          // 默认播放第一条音频
          let defaultAudioIndex = 0;
          for (let i = 0; i < list.length; i++) {
            // @ts-ignore
            if (list[i].isBuy === 1 || Number(list[i].price) === 0) {
              defaultAudioIndex = i;
              hasFreeVoice = true;
              break;
            }
          }
          // 没有免费语音且未登录，提示登陆
          if (!hasFreeVoice && !this.$store.state.loginState) {
            this.$store.commit("setShowVerification", false);
            return false;
          }
          // 没有免费语音且用户已登录，跳转金牌解说列表
          if (!hasFreeVoice && this.$store.state.loginState) {
            this.$router.push({
              path: "/jpjs-page",
              query: {
                // @ts-ignore
                senicId: point.jpjsCode,
                // @ts-ignore
                senicName: point.name,
                // @ts-ignore
                spotId: point.id
              }
            });
          }
          // 有免费语音，或者有用户已经购买的语音
          if (hasFreeVoice) {
            // @ts-ignore
            this.currentVoice = list[defaultAudioIndex];
            // @ts-ignore
            this.$refs.audio.src = list[defaultAudioIndex].voiceUrl;
            // @ts-ignore
            this.voicePlayStatus = true;
            // this.$refs.audio.play();

            this.currentVoiceType = list[defaultAudioIndex].languageType || 0;
            this.currentInterpretationType =
              list[defaultAudioIndex].quarterType || 0;
            this.currentSoundType = list[defaultAudioIndex].voiceType || 0;
          }
          list.length > 1
            ? (this.hasMultipleVoice = true)
            : (this.hasMultipleVoice = false);
          // this.playVoice(this.list[defaultAudioIndex])
          // this.lineVoiceData = list || [];
        }
        // 设置金牌解说支付地址url 暂未用
        // this.$store.commit("setJpjsPayUrl", res.extend);
      }
    });
  }
  // 点击mini播放器专辑头像跳转
  private goToJpjsDtail() {
    // 跳转解说员列表
    if (this.$route.name === "guide-album") {
      return false;
    }
    // if (this.hasMultipleVoice && this.$route.name !== "jpjs-page") {
    if (this.hasMultipleVoice && this.$route.name !== "jpjs-page") {
      let obj = {
        // @ts-ignore
        currentSoundType:
          this.currentSoundType >= 0 ? this.currentSoundType : "",
        // @ts-ignore
        currentVoiceType:
          this.currentVoiceType >= 0 ? this.currentVoiceType : "",
        // @ts-ignore
        currentInterpretationType:
          this.currentInterpretationType >= 0
            ? this.currentInterpretationType
            : ""
      };
      this.$store.commit("setCurrentLangageType", {});
      this.$store.commit("setCurrentLangageType", obj);
      this.$router.push({
        path: "/jpjs-page",
        query: {
          // @ts-ignore
          senicId: this.activePoint.jpjsCode,
          // @ts-ignore
          senicName: this.activePoint.name,
          // @ts-ignore
          spotId: this.activePoint.id
        }
      });
    } else {
      // 跳转单个解说员详情
      // @ts-ignore
      if (this.currentVoice.voicePacketId) {
        this.$router.push({
          // @ts-ignore
          path: `/guide-album/${this.currentVoice.voicePacketId}`
        });
      }
    }
  }
  // 设置根据播放状态设置marker icon
  private setScenicMarkerIcon(reset: Boolean = false, point: Object) {
    if (!Object.keys(point).length) {
      return false;
    }
    const senicVoiceMarker = require("@/assets/images/marker/senic-hasvoice-marker.png");
    const senicNotPlayVoiceMarker = require("@/assets/images/marker/senic-notplayvoice-marker.png");
    const senicPlayingVoiceMarker = require("@/assets/images/marker/senic-playvoice-marker.gif");
    // @ts-ignore
    if (!document.getElementById(`scenic-marker-${point.id}`)) {
      return false;
    }
    // @ts-ignore
    // marker显示的图片dom
    // eslint-disable-next-line prettier/prettier
    const markerImg: HTMLImageElement = document .getElementById(`scenic-marker-${point.id}`).getElementsByTagName("img")[0];
    // @ts-ignore
    // 线路marker显示序号dom
    // eslint-disable-next-line prettier/prettier
    const markerIndex: HTMLElement = document.getElementById(`line-index-${point.id}`
    );
    // 重置
    if (reset) {
      markerImg ? (markerImg.src = senicVoiceMarker) : false;
      markerImg
        ? (markerImg.style.transform = "scale(1.15) translateY(0px)")
        : false;
      markerIndex ? (markerIndex.style.display = "block") : false;
    } else if (this.isPlayVoice && this.voicePlayStatus) {
      markerImg ? (markerImg.src = senicPlayingVoiceMarker) : false;
      markerImg
        ? (markerImg.style.transform = "scale(1.5) translateY(-6px)")
        : false;
      markerIndex ? (markerIndex.style.display = "none") : false;
    } else if (this.isPlayVoice && !this.voicePlayStatus && !reset) {
      markerImg ? (markerImg.src = senicNotPlayVoiceMarker) : false;
      markerIndex ? (markerIndex.style.display = "none") : false;
    } else {
      markerImg ? (markerImg.src = senicVoiceMarker) : false;
      markerImg
        ? (markerImg.style.transform = "scale(1.15) translateY(0px)")
        : false;
      markerIndex ? (markerIndex.style.display = "block") : false;
    }
  }
  // 拖动距离
  private touchDistance: {
    x: Number;
    y: Number;
  } = {
    x: 0,
    y: 0
  };
  // 开始拖动的坐标
  private startPosition: {
    x: Number;
    y: Number;
  } = {
    x: 0,
    y: 0
  };
  // 开始拖动播放器
  private touchPlayerStart(e: TouchEvent) {
    this.startPosition.x = e.touches[0].clientX;
    this.startPosition.y = e.touches[0].clientY;
  }
  // 拖动播放器
  private touchPlayerMove(e: TouchEvent) {
    // @ts-ignore
    this.touchDistance.x = e.touches[0].clientX - this.startPosition.x;
    // @ts-ignore
    this.touchDistance.y = e.touches[0].clientY - this.startPosition.y;
    // 判断是否超过屏幕视口
    // @ts-ignore
    if (this.$refs.miniPlayer && this.$refs.miniPlayer.offsetLeft < 0) {
      // 不允许超过左边边界
      // @ts-ignore
      this.$refs.miniPlayer.style.left = 0;
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.top =this.touchDistance.y + this.$refs.miniPlayer.offsetTop + "px";
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
    } else if (this.$refs.miniPlayer && this.$refs.miniPlayer.offsetLeft >window.screen.width * window.devicePixelRatio -  this.$refs.miniPlayer.offsetWidth
    ) {
      // 不允许超过右边边界
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.left = window.screen.width * window.devicePixelRatio -this.$refs.miniPlayer.offsetWidth +"px";
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.top =this.touchDistance.y + this.$refs.miniPlayer.offsetTop + "px";
      // @ts-ignore
    } else if (this.$refs.miniPlayer && this.$refs.miniPlayer.offsetTop < 0) {
      // 不允许超过上面边界
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.left =this.touchDistance.x + this.$refs.miniPlayer.offsetLeft + "px";
      // @ts-ignore
      this.$refs.miniPlayer.style.top = 0;
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
    } else if (this.$refs.miniPlayer.offsetTop > window.screen.height * window.devicePixelRatio - this.$refs.miniPlayer.offsetHeight) {
      // 不允许超过下面边界
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.left =this.touchDistance.x + this.$refs.miniPlayer.offsetLeft + "px";
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.top =window.screen.height * window.devicePixelRatio - this.$refs.miniPlayer.offsetHeight +"px";
    } else {
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.left =this.touchDistance.x + this.$refs.miniPlayer.offsetLeft + "px";
      // @ts-ignore
      // eslint-disable-next-line prettier/prettier
      this.$refs.miniPlayer.style.top =this.touchDistance.y + this.$refs.miniPlayer.offsetTop + "px";
    }
    this.startPosition.x = e.touches[0].clientX;
    this.startPosition.y = e.touches[0].clientY;
  }
}
