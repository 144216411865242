import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import CONFIG from "@/util/config";
import store from "@/store";
import qs from "qs";
import router from "@/router";
axios.defaults.baseURL = CONFIG.url;
axios.defaults.timeout = 30000;

// 统计接口使用情况
let ajaxList: any = {};

// 添加请求拦截器

// @ts-ignore
axios.interceptors.request.use(config => {
  // 默认参数
  let defaultParams: any = {
    // vcode: localStorage.getItem('vcode'),
    // token: localStorage.getItem('token' + localStorage.getItem('vcode'))
  };
  // 判断是否存在token，如果存在则每个页面header都添加token
  if (localStorage.getItem("token")) {
    config.headers.common["ACCOUNT"] = localStorage.getItem("token");
  }
  if (sessionStorage.getItem("connectId")) {
    config.headers.common["connectId"] = sessionStorage.getItem("connectId");
  }

  // 测试用，正式环境不用
  // config.headers.common["domain"] = "jq";
  // config.headers.common["domain"] = "site000071";  // 青城山
  // config.headers.common["domain"] = "site000027";  // 巫山
  // config.headers.common["domain"] = "site000026";  // 汉中
  // config.headers.common["domain"] = "site000023";
  // config.headers.common["domain"] = "site000021"; // 剑门关
  // config.headers.common["domain"] = "site000008";
  // config.headers.common["domain"] = "site000022";   // 松坪沟
  // config.headers.common["domain"] = "site000011";  //  邛海
  // config.headers.common["domain"] = "site000033"; // 南投古城

  // 正式环境用，测试环境不用
  // sessionStorage.getItem("siteCode") ||
  config.headers.common["domain"] = "site000021";
  // 需要排除请求中可能存在的经纬度数据，否则会造成严重的重复统计
  let tempconfig = { ...config.params, ...config.data };
  delete tempconfig.longitude;
  delete tempconfig.latitude;
  delete tempconfig.currPage;
  let key = `${config.url}${JSON.stringify(tempconfig)}`;
  // 如果当前请求列表中不存在 则为第一次使用接口，否则为二次调用
  if (!ajaxList[key]) {
    ajaxList[key] = true;
    config.headers["isNew"] = true;
  }
  // 若为全连接 则直接访问
  if (
    !(config.url.indexOf("http://") > -1 || config.url.indexOf("https://") > -1)
  ) {
    if (config.method === "get") {
      config.params = Object.assign({}, config.params, defaultParams);
    } else if (config.method === "post") {
      // 判断是否文件上传,上传用multipart/form-data
      // 用户登录和保存评论、收藏用body传参，其余用form传参
      // console.log(config)
      if (
        config.url.indexOf("common/uploadFile") > -1 ||
        config.url.indexOf("common/uploadFiles") > -1
      ) {
        config.headers["Content-Type"] = "multipart/form-data";
        config.data = Object.assign(config.data);
      } else if (
        config.url.indexOf("evaluate/save") > -1 ||
        config.url.indexOf("user/") > -1 ||
        config.url.indexOf("userFavorite/add") > -1 ||
        config.url.indexOf("res/search/list") > -1 ||
        config.url.indexOf("complaint/save") > -1 ||
        config.url.indexOf("danger-event/save") > -1 ||
        config.url.indexOf("/res/lonlat/view/all") > -1 ||
        config.url.indexOf("/res/lonlat/view/page") > -1
      ) {
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
        config.data = Object.assign({}, defaultParams, config.data);
      } else {
        config.data = Object.assign({}, defaultParams, config.data);
        config.transformRequest = [
          function(data: any) {
            return qs.stringify(data);
          }
        ];
      }
    }
  }
  return config;
});

// 添加响应拦截器
axios.interceptors.response.use(
  (response: any) => {
    // console.log(response)
    // 请求成功响应后 删掉请求列表对应数据
    let config = response.config;

    if (response.data.code === 2) {
      store.commit("setShowVerification", true);
      return response;
    }
    if (response.data.code === 4) {
      router.push({ path: "/lack-page", query: { msg: "景区不存在!" } });
      return response;
    }
    if (response.data.code === 5) {
      router.push({ path: "/lack-page", query: { msg: "停止服务!" } });
      return response;
    }
    return response;
  },
  function(error: any) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

/**
 * 封装请求
 * @param config
 */
const ajax = (config: object): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axios(config)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default ajax;
