import ajax from "@/util/request";

// 上报事件
export const reportEvent = (data?: object) =>
  ajax({ url: "danger-event/save", data, method: "post" });
// 单张上传图片

export const uploadImage = (data?: object) =>
  ajax({ url: "common/uploadFile", data, method: "post", isUpload: true });

// 上传 多张图片
export const uploadImages = (data?: object) =>
  ajax({ url: "common/uploadFiles", data, method: "post", isUpload: true });

// 获取上传文件的key
export const getuploadossKey = (params?: object) =>
  ajax({ url: "scenic/ossKey", params });

// 发送统计二维码扫码数量
export const sendQrpvCount = (params?: object) =>
  ajax({ url: "scenic/qrPv", params });

// 发送统计二维码扫码数量
export const footprint = (params?: object) =>
  ajax({ url: "scenic/footprint", params });

// 当前用户是否关注公众号
export const getisSubscribe = (params?: object) =>
  ajax({ url: "authorize/isSubscribe", params });

//用户登录
export const userLogin = (data?: object) =>
  ajax({ url: "user/login", data, method: "post" });

//用户退出
export const userLogout = (params?: object) =>
  ajax({ url: "user/logout", params });

// 获取微信 access-token
export const getJsInfo = (params?: object) =>
  ajax({ url: "authorize/jsInfo", params });

// 景区菜单
export const getScenicMenu = (params?: object) =>
  ajax({ url: "res/index/menuList", params });

// 关键字搜索功能
export const getSearchList = (data?: object) =>
  ajax({ url: "res/search/list", data, method: "post" });
// 获取景区首页地图
export const getIndexMapUrl = (params?: object) =>
  ajax({ url: "indexMapUrl", params });

// 获取微信code
export const getBaseRequest = (params?: object) =>
  ajax({ url: "authorize/baseRequest", params });

// 获取用户数据
export const getUserInfo = (params?: object) =>
  ajax({ url: "user/info", params });

//更新用户信息
export const updateUserInfo = (data?: object) =>
  ajax({ url: "user/update", data, method: "post" });

//我的-用户中心
export const getProfile = (params?: object) =>
  ajax({ url: "/user/profile", params });

//发送验证码
export const getVerificationCode = (params?: object) =>
  ajax({ url: "user/sendCode", params });

// 验证旧手机验证码
export const verifyCode = (data?: object) =>
  ajax({ url: "user/verifyCode", data, method: "post" });

// 保存新手机号
export const saveNewPhone = (data?: object) =>
  ajax({ url: "user/saveNewPhone", data, method: "post" });

// 获取景区数据
export const getScenicInfo = (params?: object) =>
  ajax({ url: "scenic/info", params });

// 获取首页广告
export const getAdvertisement = (params?: object) =>
  ajax({ url: "res/index/advertisement", params });

// 获取 寻线路列表数据
export const getRouteList = (data?: object) =>
  ajax({ url: "res/route/list", data, method: "post" });

// 获取 线路详情数据
export const getRouteInfo = (params?: object) =>
  ajax({ url: "res/route/routePoints", params });
// 根据类型获取资源列表
export const getrouteSourceList = (params?: object) =>
  ajax({ url: "res/route/routeSourceList", params });
// 获取 美食列表数据
export const getFoodList = (data?: object) =>
  ajax({ url: "res/food/list", data, method: "post" });

// 获取 美食详情
export const getFoodInfo = (params?: object) =>
  ajax({ url: "res/food/info", params });

// 获取 厕所列表数据
export const getToiletList = (data?: object) =>
  ajax({ url: "res/toilet/list", data, method: "post" });

// 获取 厕所详情数据
export const getToiletInfo = (params?: object) =>
  ajax({ url: "res/toilet/info", params });

// 获取 服务点数据
export const getServiceList = (params?: object) =>
  ajax({ url: "scenic/toiletList", params });

// 获取 停车场列表数据
export const getParkingList = (params?: object) =>
  ajax({ url: "res/parking/list", params, method: "post" });

//获取 停车场详情数据
export const getParkingInfo = (params?: object) =>
  ajax({ url: "res/parking/info", params });

// 获取 景区地图信息
export const getSpotsMap = (params?: object) =>
  ajax({ url: "res/index/map", params });

// 获取 景区详情
export const getScenicDetial = (params?: object) =>
  ajax({ url: "res/spot/info", params });

// 获取 观花点详情
export const getFlowerScenicDetial = (params?: object) =>
  ajax({ url: "res/flower-spot/info", params });

// 获取 景区简介描述内容
export const getScenicIntroduce = (params?: object) =>
  ajax({ url: "res/scenicIntro/info", params });

// 获取 简易天气数据
export const getSimpleWeather = (params?: object) =>
  ajax({ url: "res/index/simpleWeather", params });

// 获取 天气详情
export const getWeatherInfo = (params?: object) =>
  ajax({ url: "res/index/weather", params });

// 获取 吐槽相关信息 是否已经吐槽
export const getPublishComplain = (params?: object) =>
  ajax({ url: "complain/publishComplain", params });

// 发布吐槽
export const saveComplain = (data?: object) =>
  ajax({ url: "complaint/save", data, method: "post" });

// 获取槽点列表
export const getComplaintPoints = (data?: object) =>
  ajax({ url: "complaint/complaintPoints", data, method: "post" });

// 获取吐槽 列表
export const getComplainRecordList = (data?: object) =>
  ajax({ url: "complaint/list", data, method: "post" });

// 获取吐槽回复列表
export const getComplainReply = (params?: object) =>
  ajax({ url: "complain/getComplainReply", params });

// 吐槽回复
export const saveComplainReply = (data?: object) =>
  ajax({ url: "complain/saveComplainReply", data, method: "post" });

// 用户收藏列表
export const getFavoriteList = (data?: object) =>
  ajax({ url: "userFavorite/list", data, method: "post" });

// 用户景点收藏列表
export const getFavoriteSpotList = (data?: object) =>
  ajax({ url: "userFavorite/spotList", data, method: "post" });

// 评论分类
export const getEvaluateConfig = (params?: object) =>
  ajax({ url: "evaluate/appEvaluateTypes", params });

// 获取评论时 的评价类型配置
export const getResourceEvaluateConfig = (params?: object) =>
  ajax({ url: "evaluate/getResourceEvaluateConfig", params });

// 获取评价列表数据
export const getEvaluateList = (params?: object) =>
  ajax({ url: "evaluate/list", params });

// 获取评价 区块链信息
export const getCheckEvaluateData = (params?: object) =>
  ajax({ url: "evaluate/checkData", params });

// 添加评论
export const saveEvaluate = (data?: object) =>
  ajax({ url: "evaluate/save", data, method: "post" });

//评论项目
export const appEvaluateItems = (params?: object) =>
  ajax({ url: "evaluate/appEvaluateItems", params });

// 获取评价有礼 相关数据
export const getCommentGiftdataInfo = (params?: object) =>
  ajax({ url: "commentGift/dataInfo", params });

// 添加、取消收藏
export const saveCollection = (data?: object) =>
  ajax({ url: "commentGift/collection", data, method: "post" });

//保存收藏
// export const saveCollection = (data?: object) => ajax({ url: 'userFavorite/add', data, method: 'post' })

//取消收藏指定记录
// export const deleteCollection = (params?: object) => ajax({ url: 'userFavorite/del', params })

//指定收藏记录详情
export const favoriteInfo = (params?: object) =>
  ajax({ url: "userFavorite/info", params });

// 删除收藏
export const deleteCollection = (data?: object) =>
  ajax({ url: "favorite/delete", data, method: "post" });

// 是否有未读活动消息
export const getActivityNotice = (params?: object) =>
  ajax({ url: "res/activity/hasNoRead", params });

// 获取活动列表是否有数据
export const getActivityCount = (params?: object) =>
  ajax({ url: "scenic/activityCount", params });

// 获取活动列表数据
export const getActivityList = (data?: object) =>
  ajax({ url: "res/activity/list", data, method: "post" });

// 获取视频列表数据
export const getVideosList = (data?: object) =>
  ajax({ url: "res/videos/list", data, method: "post" });

// 获取活动详情
export const getFavoriteInfo = (params?: object) =>
  ajax({ url: "res/activity/info", params });

// 收藏活动
export const addFavorite = (data?: object) =>
  ajax({ url: "userFavorite/add", data, method: "post" });

// 取消收藏活动
export const deleteFavorite = (params?: object) =>
  ajax({ url: "userFavorite/del", params });

// 获取金牌解说数据
export const getScenicVoiceData = (params?: object) =>
  ajax({ url: "jpjs/scenicVoiceData", params, method: "post" });

// 可根据线路id 获取金牌解说数据
export const getLineVoiceData = (params?: object) =>
  ajax({ url: "jpjs/routeVoiceData", params });

// 可根据景点ID 获取金牌解说数据
export const getSpotVoiceData = (params?: object) =>
  ajax({ url: "jpjs/spotVoiceData", params });

// 可根据观花点ID 获取金牌解说数据
export const getFlowerSpotVoiceData = (params?: object) =>
  ajax({ url: "jpjs/flowerSpotVoiceData", params });

// 获取金牌解说语音包详情
export const getBagDetail = (params?: object) =>
  ajax({ url: "jpjs/bagDetail", params });

// 获取金牌解说语音包评价详情
export const getJpjsCommentList = (params?: object) =>
  ajax({ url: "jpjs/commentList", params });

// 获取线路语音详情
export const getRouteVoiceData = (params?: object) =>
  ajax({ url: "jpjs/routeVoiceData", params });

// 提交金牌解说评论
export const submitCommentInfo = (data?: object) =>
  ajax({ url: "jpjs/submitCommentInfo", data, method: "post" });

// 提交金牌解说 语音播放记录
export const submitListenRecord = (data?: object) =>
  ajax({ url: "jpjs/submitListenRecord", data, method: "post" });

// 足迹-点位上传
export const saveFootPrint = (data?: object) =>
  ajax({ url: "footPrint/saveFootPrint", data, method: "post" });

// 获取 所有景点列表
export const getSpotsList = (data?: object) =>
  ajax({ url: "res/spot/list", data, method: "post" });

// 获取 所有观花点列表
export const getFlowerSpotsList = (data?: object) =>
  ajax({ url: "res/flower-spot/list", data, method: "post" });

// 获取 侧边功能菜单显示权限
export const getMenuLeft = (params?: object) =>
  ajax({ url: "scenic/menuLeft", params });

// 游客驻留时长  点位上传
export const saveTouristStay = (data?: object) =>
  ajax({ url: "footPrint/saveTouristStay", data, method: "post" });

// 获取 游园区 楼栋列表
export const getBuildingList = (data?: object) =>
  ajax({ url: "res/building/list", data, method: "post" });

// 获取 园区楼栋详情
export const getBuildingInfo = (params?: object) =>
  ajax({ url: "res/building/info", params });

// 获取 游园区 楼栋公司列表
export const getCompanyList = (params?: object, routePath?: string | number) =>
  ajax({ url: `res/building/companyList`, params });

// 获取 游园区 公司企业详情
export const getCompanyDetail = (params?: object, routePath?: any) =>
  ajax({ url: `res/building/companyInfo`, params });

// 获取 出入口列表数据
export const getGateList = (data?: object) =>
  ajax({ url: `res/gate/list`, data, method: "post" });

// 获取出入口详情数据
export const getGateInfo = (params?: object) =>
  ajax({ url: "res/gate/info", params });

// 获取 酒店列表数据
export const getHotelList = (data?: object) =>
  ajax({ url: `res/hotel/list`, data, method: "post" });

// 获取 酒店 详情数据
export const getHotelInfo = (params?: object) =>
  ajax({ url: `res/hotel/info`, params });

// 获取 购物店列表数据
export const getShoppingList = (data?: object) =>
  ajax({ url: `res/shop/list`, data, method: "post" });

// 获取 购物 详情数据
export const getShoppingInfo = (params?: object) =>
  ajax({ url: `res/shop/info`, params });

// 获取 享交通数据
export const getTrafficList = (data?: object) =>
  ajax({ url: "res/traffic/info", data, method: "post" });

// 获取 享生活列表
export const getTLifeList = (data?: object) =>
  ajax({ url: "res/enjoyLife/list", data, method: "post" });

// 获取 享生活详情数据
export const getLifeInfo = (params?: object) =>
  ajax({ url: `res/enjoyLife/info`, params });

// 获取 乘览点，医务室，售票处，服务点数据
export const getStationList = (data?: object) =>
  ajax({ url: "res/station/list", data, method: "post" });

// 获取金牌解说购买地址
export const getjpBuyUrl = (params?: object) =>
  ajax({ url: "jpjs/extend", params });

// 获取乘游船列表
export const getBoatList = (data?: object) =>
  ajax({ url: "res/boat/list", data, method: "post" });

// 获取找茶馆列表
export const getTeaList = (data?: object) =>
  ajax({ url: "res/tea/list", data, method: "post" });

// 获取找茶馆详情数据
export const getTeaDetial = (params?: object) =>
  ajax({ url: "res/tea/info", params });

// 获取汽车站列表
export const getBusStationList = (data?: object) =>
  ajax({ url: "res/busStation/list", data, method: "post" });

// 获取租单车列表
export const getCyclingList = (data?: object) =>
  ajax({ url: "res/cycling/list", data, method: "post" });

// 获取观光车列表
export const getSightStationList = (data?: object) =>
  ajax({ url: "res/sightStation/list", data, method: "post" });

// 获取首页公告滚动消息
export const getNewestList = (params?: object) =>
  ajax({ url: "res/scenic-notice/newest-list", params });

// 获取首公告列表
export const getNewestPage = (params?: object) =>
  ajax({ url: "res/scenic-notice/view/page", params });

// 获取首公告详情
export const getNewestDetail = (params?: object) =>
  ajax({ url: `res/scenic-notice/info/${params}` });

// 获取景区的下级景区
export const getScenicChildAll = (params?: object) =>
  ajax({ url: "res/index/all-scenic-child", params });

// 查询是否展示客流、满意度、投诉
export const getServiceConfig = (params?: object) =>
  ajax({ url: "res/index/tool/service-config", params });

// 获取客流概况
export const getCustormFlow = (params?: object) =>
  ajax({ url: "res/index/scenic-customer-flow", params });

// 获取实时观光车点位
export const getCarLocation = (params?: object) =>
  ajax({ url: "res/index/car-location", params });

// 查询当前景区是否存在父级景区
export const getParentScenic = (params?: object) =>
  ajax({ url: "res/index/parent-scenic", params });

// 获取 景区简介描述内容
export const getMultiVoice = (params?: object) =>
  ajax({ url: "res/scenic/info", params });

// 获取语言分类 -景点
export const getVoiceDetailInfos = (params?: object) =>
  ajax({ url: "jpjs/spotVoiceType", params });

// 获取语言分类 -线路
export const getLineVoiceDetailInfos = (params?: object) =>
  ajax({ url: "jpjs/routeVoiceType", params });

// 获取语言分类 -观花点
export const getFlowerVoiceDetailInfos = (params?: object) =>
  ajax({ url: "jpjs/flowerSpotVoiceType", params });
