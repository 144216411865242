import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
interface LngLatObject {
  lng: string | number;
  lat: string | number;
}
interface UserInfoClass {
  id: number;
  jpjsOpenId: string;
  unionId: string;
  openId: string;
  token: string;
  headImgUrl: string;
  phone: string;
  nickname: string;
  sex: string;
  avatar: string;
  userSn: string;
}

export default new Vuex.Store({
  state: {
    vcode: "",
    lng: "", // 当前定位经度位置
    lat: "", // 当前定位维度位置
    showimagePreview: false, // 显示图片预览
    firstShowLocationTips: true, // 是否为第一次展示定位失败 提示语
    activeType: "", // 景区导览 菜单栏 当前选中项类型
    activeFootBox: false, // 底部列表是否弹起
    currsenicID: "", // 当前选中线路中景点 ID
    currsenicIndex: "", // 当前选中线路中景点 在线路中的序号
    currsenicIndexType: "", // 当前选中景点类型 餐饮、线路、厕所、停车场
    routePathID: "", // 当前线路ID，用户金牌解说接口使用
    routePathPoint: {}, // 当前线路的点位数据
    showVerification: false, //是否显示验证码弹窗
    resCode: 0, //接口返回的code若为2,调出用户登录页面
    loginState: false, //用户是否登录
    userName: "", // 用户是否修改昵称
    routerPath: "", // 当前路由名
    saveRouterPath: "", // 保存当前路由名
    currentSenic: null, //当前景点id
    routeArrowData: {
      show: false, // 是否显示浮框
      showTo: "", // 浮框中 即将到达的景点
      nowIndex: "",
      nowID: "", // 最近到达的景点ID
      nowName: "", // 最近到达的景点名称
      nextID: "",
      nextName: "",
      prevID: "",
      prevName: ""
    },
    routeArrow: true, // 当前指示 方向 true正向；false逆向
    jpjsSenicID: null, // 金牌解说 选择的景点点位ID
    jpjsSenicName: "", // 金牌解说 选择的景点点位名称
    jpjsPayUrl: "", // 金牌解说购买专辑 url地址
    notifyUrl: "", // 金牌解说购买专辑 notifyUrl参数 回调url地址
    showOverlay: false, // 遮罩层的显示
    checkedPantoLocation: false, // 定位成功后地图是否以此为中心点
    showapp: true, // 显示app页面
    transitionName: "", // 路由转场名称
    osskey: "", // 文件上传所需key
    senicData: {
      name: "",
      lng: "",
      lat: ""
    },
    openFootprint: false, // 是否开启足迹功能
    userInfo: {
      id: "",
      jpjsOpenId: "",
      unionId: "",
      openId: "",
      token: "",
      headImgUrl: "",
      phoneNo: "",
      nickName: "",
      sex: "",
      avatar:
        "http://file.daqsoft.com/uploadfile/server/geekui/geekUi/defaultAvatar.jpg",
      userSn: ""
    },
    // 金牌解说专辑解说员 基本信息
    albumInfo: {
      headImgUrl: "", // 头像
      name: "", // 姓名
      label: "", // 标签
      hotlevel: 0 // 评分
    },
    // 评价列表进入详情所需数据
    evaluateDetial: {
      headImgUrl: "",
      nickName: "",
      score: 5,
      evaluateTime: "",
      content: "",
      auditTime: "",
      txId: "",
      imageUrl: []
    },
    audioData: {
      playUrl: "", // 播放的url
      title: "", // 播放音乐的标题
      playStatus: false, // 是否正在播放
      tryPlay: false, // 是否试听播放
      img: "", // 专辑图片
      bagID: "", // 当前播放解说对应的专辑ID
      list: [] // 专辑对应的播放列表数据
    },
    // 请求授权成功之后刷新接口
    getPosition: false,
    // 同意隐私协议
    agreePrivacy: false,
    // 隐私协议跳转路径
    privacyRouter: "",
    // 实时消息接收
    realMessage: "",
    // vr地址
    vrAddress: "",
    // 经纬度保存
    locationLngLat: {},
    // 当前解说类型
    currentLangageType: {
      languageType: "",
      quarterType: "",
      voiceType: ""
    },
    // 当前解说的景点ID
    currentCommentarySpot: {
      id: 0,
      jspsCode: 0
    },
    // 是否关闭地图聚合功能
    showMarkerClusterer: true,
    // 是否展示本地上传听解说菜单
    isShowCommentaryLocal: false,
    // 是否定位完成（解决定位缓慢导致页面加载问题）
    isPositioningComplete: false
  },
  mutations: {
    // 设置 showapp
    setShowapp(state: any, payload: boolean) {
      state.showapp = payload;
    },
    // 设置 showapp
    setPositioningComplete(state: any, payload: boolean) {
      state.isPositioningComplete = payload;
    },
    // 设置 MarkerClusterer
    setMarkerClusterer(state: any, payload: boolean) {
      state.showMarkerClusterer = payload;
    },
    // 设置 听解说本地上传展示样式
    setIsShowCommentaryLoca(state: any, payload: boolean) {
      state.isShowCommentaryLocal = payload;
    },
    // 设置vcode
    setVcode(state: any, payload: string) {
      state.vcode = payload;
    },
    // 显示遮罩
    showOverlay(state: any) {
      state.showOverlay = true;
    },
    // 隐藏遮罩
    hideOverlay(state: any) {
      state.showOverlay = false;
    },
    setCurrentSenic(state: any, payload: string) {
      state.currentSenic = payload;
    },
    // 保存路由名
    setRouterPath(state: any, payload: string) {
      state.routerPath = payload;
    },
    // 保存当前路由名
    setSaveRouterPath(state: any, payload: string) {
      state.saveRouterPath = payload;
    },
    //设置接口返回的code
    setResCode(state: any, payload: number) {
      state.resCode = payload;
    },
    //设置用户是否登录
    setLoginState(state: any, payload: boolean) {
      state.loginState = payload;
    },
    // 设置 showapp
    setUserName(state: any, payload: string) {
      state.userName = payload;
    },

    // 设置 用户信息
    setUserInfo(state: any, payload: UserInfoClass) {
      state.userInfo.id = payload.id;
      state.userInfo.jpjsOpenId = payload.jpjsOpenId || "";
      state.userInfo.unionId = payload.unionId || "";
      state.userInfo.openId = payload.openId || "";
      state.userInfo.token = payload.token || "";
      state.userInfo.headImgUrl = payload.headImgUrl || "";
      state.userInfo.phoneNo = payload.phone || "";
      state.userInfo.nickName = payload.nickname || "";
      state.userInfo.sex = payload.sex || "";
      state.userInfo.avatar =
        payload.avatar ||
        "http://file.daqsoft.com/uploadfile/server/geekui/geekUi/defaultAvatar.jpg";
      state.userInfo.userSn = payload.userSn || "";
      state.locationLngLat = {};
      state.privacyRouter = "";
      // 实时消息接收
      state.realMessage = "";
      // vr地址
      state.vrAddress = "";
      state.currentLangageType = {
        languageType: "",
        quarterType: "",
        voiceType: ""
      };
      state.currentCommentarySpot = {
        spotId: 0,
        jspsCode: 0
      };
    },
    // 设置 图片预览显示隐藏
    setShowimagePreview(state: any, payload: boolean) {
      state.showimagePreview = payload;
    },
    // 设置 是否显示验证码弹窗
    setShowVerification(state: any, payload: boolean) {
      state.showVerification = payload;
    },
    // 设置 已经显示过 定位失败提示语
    setFirstShowLocationTips(state: any) {
      state.firstShowLocationTips = false;
    },
    // 设置 当前定位经纬度
    setLocationLngLat(state: any, payload: LngLatObject) {
      state.lng = payload.lng;
      state.lat = payload.lat;
    },
    // 设置景区导览 菜单栏 当前选中项类型
    setActiveType(state: any, payload: string) {
      state.activeType = payload;
      // 切换菜单时，清空点击的金牌解说景点ID缓存
      state.jpjsSenicID = "";
    },
    // 设置底部弹窗  是否弹起
    setActiveFootBox(state: any, payload: boolean) {
      state.activeFootBox = payload || false;
    },
    // 设置 文件上传所需的key
    setOssKey(state: any, payload: boolean) {
      state.osskey = payload;
    },
    // 设置 当前线路选中的景点 ID
    setCurrsenicID(state: any, payload: any) {
      state.currsenicID = payload.id;
      state.currsenicIndex = payload.index || 0;
      state.currsenicIndexType = payload.type || "";
    },
    // 设置 当前线路ID，用户金牌解说接口使用
    setRoutePathID(state: any, payload: string) {
      state.routePathID = payload;
    },
    // 设置 当前线路详情
    setRoutePathPoint(state: any, payload: object) {
      state.routePathPoint = payload;
    },
    // 设置 最近到达的景点ID
    setRouteArrowData(state: any, payload: any) {
      state.routeArrowData.show = payload.show;
      state.routeArrowData.showTo = payload.showTo;
      state.routeArrowData.nowIndex = payload.nowIndex;
      state.routeArrowData.nowID = payload.nowID;
      state.routeArrowData.nowName = payload.nowName;
      state.routeArrowData.nextID = payload.nextID || "";
      state.routeArrowData.nextName = payload.nextName || "";
      state.routeArrowData.prevID = payload.prevID || "";
      state.routeArrowData.prevName = payload.prevName || "";
    },
    // 设置 景点正向游览
    setRouteArrow(state: any, payload: boolean) {
      state.routeArrow = payload;
    },
    // 设置 金牌解说 选择的景点点位ID
    setJpjsSenicID(state: any, payload: any) {
      state.jpjsSenicID = payload.id;
      state.jpjsSenicName = payload.name;
    },
    // 设置 景区信息
    setSenicData(state: any, payload: any) {
      state.senicData.name = payload.name || "";
      state.senicData.lng = payload.lng || "";
      state.senicData.lat = payload.lat || "";
    },
    // 设置 金牌解说支付页面url地址
    setJpjsPayUrl(state: any, payload: any) {
      state.jpjsPayUrl = payload.payUrl || "";
      state.notifyUrl = payload.notifyUrl || "";
    },
    // 设置 金牌解说员基本信息
    setAlbumInfo(state: any, payload: any) {
      state.albumInfo.headImgUrl = payload.headImgUrl || "";
      state.albumInfo.name = payload.name || "";
      state.albumInfo.label = payload.label || "";
      state.albumInfo.hotlevel = payload.hotlevel || 0;
    },
    // 设置评价详情所需数据
    setEvaluateDetial(state: any, payload: any) {
      state.evaluateDetial.headImgUrl = payload.headImgUrl || "";
      state.evaluateDetial.nickName = payload.nickName || "";
      state.evaluateDetial.score = payload.score || 5;
      state.evaluateDetial.evaluateTime = payload.evaluateTime || "";
      state.evaluateDetial.content = payload.content || "";
      state.evaluateDetial.auditTime = payload.auditTime || "";
      state.evaluateDetial.txId = payload.txId || "";
      state.evaluateDetial.imageUrl = payload.imageUrl || [];
    },
    // 设置金牌解说播放音频信息
    setAudioData(state: any, payload: any) {
      state.audioData.playUrl = payload.playUrl || "";
      state.audioData.title = payload.title || "";
    },
    // 设置定位移动地图开关
    setCheckedPantoLocation(state: any, payload: boolean) {
      state.checkedPantoLocation = payload || false;
    },
    // 设置金牌解说播放音频信息
    setAudioImg(state: any, payload: any) {
      state.audioData.img = payload || "";
    },
    // 设置金牌解说播放 状态 true 播放中   false 暂停
    setAudioPlayStatus(state: any, payload: boolean) {
      state.audioData.playStatus = payload || false;
    },
    // 设置金牌解说播放 是否  试听播放
    setTryStatus(state: any, payload: boolean) {
      state.audioData.tryPlay = payload || false;
    },
    // 设置金牌解说播放音频 专辑的ID
    setAudioBagID(state: any, payload: any) {
      state.audioData.bagID = payload || "";
    },
    // 设置金牌解说播放音频 专辑的列表
    setAudioList(state: any, payload: any) {
      state.audioData.list = payload || [];
    },
    setGetPosition(state: any, payload: any) {
      state.getPosition = payload || false;
    },
    // 保存是否具备隐私设置
    setAgreePrivacy(state: any, payload: any) {
      state.agreePrivacy = payload || false;
    },
    // 隐私协议跳转路径
    setPrivacyRouter(state: any, payload: any) {
      state.privacyRouter = payload || "";
    },
    // 实时消息接收
    setRealMessage(state: any, payload: any) {
      state.realMessage = payload || "";
    },
    // vr地址
    setVrAddress(state: any, payload: any) {
      state.vrAddress = payload || "";
    },
    // 设置解说类型
    setCurrentLangageType(state: any, payload: any) {
      state.currentLangageType = payload;
    },
    // 保存解说的景点信息
    setCurrentCommentarySpot(state: any, payload: any) {
      state.currentCommentarySpot = payload;
    }
  },
  actions: {},
  modules: {}
});
