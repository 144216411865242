import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";
Vue.use(VueRouter);
// 页面刷新时，重新赋值token
if (sessionStorage.getItem("token")) {
  store.state.userInfo.token = sessionStorage.getItem("token");
}

const routes = [
  {
    path: "/",
    meta: { index: 1 },
    redirect: "/index"
  },
  {
    // 欢迎页
    path: "/index",
    meta: { index: 1 },
    name: "index",
    component: () => import("@/views/components/index/index.vue")
  },
  {
    // 景区不存在
    path: "/lack-page",
    meta: { index: 1 },
    name: "/lack-page",
    component: () => import("@/views/lack-page/index.vue")
  },
  {
    // 景区选择页
    path: "/index-home",
    meta: { index: 2 },
    name: "index-home",
    component: () => import("@/views/components/index-home/index.vue")
  },
  {
    // 景区导游导览首页
    path: "/scenic-guide",
    meta: { index: 3, keepAlive: true, useCash: true },
    name: "scenic-guide",
    component: () => import("@/views/scenic-guide/index.vue")
  },
  {
    // 景区简介
    path: "/scenic-introduction",
    math: { index: 4, keepAlive: true, useCash: true },
    name: "scenic-introduction",
    component: () => import("@/views/components/scenic-introduction/index.vue")
  },
  {
    // 公告列表
    path: "/notice-list",
    math: { index: 4 },
    name: "/notice-list",
    component: () => import("@/views/notice-list/index.vue")
  },
  {
    // 公告详情
    path: "/notice-detail/:noticeId",
    math: { index: 5 },
    name: "/notice-detail",
    component: () => import("@/views/notice-list/notice-detail.vue")
  },
  {
    // 我的解说
    path: "/my-commentary",
    math: { index: 4 },
    name: "/my-commentary",
    component: () => import("@/views/components/my-commentary/index.vue")
  },
  {
    // 收藏页
    path: "/collection",
    meta: { index: 4 },
    name: "collection",
    component: () => import("@/views/components/collection/index.vue")
  },
  {
    // 景区详情
    path: "/scenic-detial",
    meta: { index: 5 },
    name: "scenic-detial",
    component: () => import("@/views/scenic-detial/index.vue")
  },
  {
    // 景区详情-景点介绍
    path: "/scenic-detial-info",
    meta: { index: 4 },
    name: "scenic-detial-info",
    component: () => import("@/views/scenic-detial/detial-info.vue")
  },
  {
    // 景区详情-观花点介绍
    path: "/scenic-detial-info-flower",
    meta: { index: 4 },
    name: "scenic-detial-info-flower",
    component: () => import("@/views/scenic-detial/detial-info-flower.vue")
  },
  {
    // 线路详情-线路介绍
    path: "/scenic-detial-info-line",
    meta: { index: 4 },
    name: "scenic-detial-info-line",
    component: () => import("@/views/scenic-detial/detial-info-line.vue")
  },
  {
    // 导游专辑
    path: "/guide-album/:id",
    meta: { index: 7 },
    name: "guide-album",
    component: () => import("@/views/guide-album/index.vue")
  },
  {
    // 专辑详情
    path: "/guide-album-detial/:id",
    meta: { index: 8 },
    name: "guide-album-detial",
    component: () => import("@/views/guide-album-detial/index.vue")
  },
  {
    // 评价列表
    path: "/evaluate-list",
    meta: { index: 4 },
    name: "evaluate-list",
    component: () => import("@/views/evaluate-list/index.vue")
  },
  {
    // 金牌解说-评价列表
    path: "/jpsjevaluate-list/:id",
    meta: { index: 4 },
    name: "jpsjevaluate-list",
    component: () => import("@/views/guide-album/evaluate.vue")
  },
  {
    // 评价详情
    path: "/evaluate-detial",
    meta: { index: 5 },
    name: "evaluate-detial",
    component: () => import("@/views/evaluate-list/detial.vue")
  },
  {
    // 评价详情 - 防伪校验页
    path: "/evaluate-detial-check/:txId",
    meta: { index: 6 },
    name: "evaluate-detial-check",
    component: () => import("@/views/evaluate-list/detial-check.vue")
  },
  {
    // 评价
    path: "/evaluate",
    meta: { index: 5 },
    name: "evaluate",
    component: () => import("@/views/evaluate/index.vue")
  },
  {
    // 金牌解说- 评价
    path: "/jpjsevaluate",
    meta: { index: 5 },
    name: "jpjsevaluate",
    component: () => import("@/views/guide-album/evaluate-add.vue")
  },
  {
    // 评价有礼
    path: "/evaluate-feedback/:id",
    meta: { index: 6 },
    name: "evaluate-feedback",
    component: () => import("@/views/evaluate-feedback/index.vue")
  },
  {
    // 吐槽
    path: "/debunk",
    meta: { index: 4 },
    name: "debunk",
    component: () => import("@/views/debunk/index.vue")
  },
  {
    // 景点收藏
    path: "/collection-list",
    meta: { index: 4 },
    name: "collection-list",
    component: () => import("@/views/collection-list/index.vue")
  },
  {
    // 吐槽详情
    path: "/debunk-detial",
    meta: { index: 5 },
    name: "debunk-detial",
    component: () => import("@/views/debunk-detial/index.vue")
  },
  {
    // 公司详情
    path: "/build-detial",
    meta: { index: 5 },
    name: "build-detial",
    component: () => import("@/views/scenic-detial/build-detial.vue")
  },
  {
    // 住宿详情
    path: "/hotel-detial",
    meta: { index: 5 },
    name: "hotel-detial",
    component: () => import("@/views/scenic-detial/hotel-detial.vue")
  },
  {
    // 购物详情
    path: "/shopping-detial",
    meta: { index: 5 },
    name: "shopping-detial",
    component: () => import("@/views/scenic-detial/shopping-detial.vue")
  },
  {
    // 享生活详情
    path: "/life-detail",
    meta: { index: 5 },
    name: "life-detail",
    component: () => import("@/views/scenic-detial/life-detail.vue")
  },
  {
    // 精彩视频
    path: "/videos-list/",
    meta: { index: 4 },
    name: "videos-list",
    component: () => import("@/views/videos-list/index.vue")
  },
  {
    // 活动列表
    path: "/activity-list/",
    meta: { index: 4 },
    name: "activity-list",
    component: () => import("@/views/activity-list/index.vue")
  },
  {
    // 活动详情
    path: "/activity-detial/:id",
    meta: { index: 5 },
    name: "activity-detial",
    component: () => import("@/views/activity-detial/index.vue")
  },
  {
    // 广告页面
    path: "/advertisement-page",
    meta: { index: 1 },
    name: "advertisement-page",
    component: () => import("@/views/advertisement-page/index.vue")
  },
  {
    // 天气
    path: "/weather",
    meta: { index: 3 },
    name: "weather",
    component: () => import("@/views/components/weather/weather.vue")
  },
  {
    // 金牌解说列表页
    path: "/jpjs-page",
    meta: { index: 5 },
    name: "jpjs-page",
    component: () =>
      import("@/views/scenic-guide/components/commentary-list-page.vue")
  },
  {
    // 美食详情
    path: "/restaurant-detial",
    meta: { index: 5 },
    name: "restaurant-detial",
    component: () => import("@/views/restaurant-detial/index.vue")
  },
  {
    // 享交通 详情
    path: "/transportation-detail",
    meta: {},
    name: "transportation-detail",
    component: () =>
      import("@/views/scenic-guide/components/transportation-detail.vue")
  },
  {
    // 医务室 列表
    path: "/clinic-list",
    meta: {},
    name: "clinic-list",
    component: () => import("@/views/scenic-guide/components/clinic-list.vue")
  },
  {
    //服务点 列表
    path: "/service-list",
    meta: {},
    name: "service-list",
    component: () => import("@/views/scenic-guide/components/service-list.vue")
  },
  {
    //售票处 列表
    path: "/ticket-list",
    meta: {},
    name: "ticket-list",
    component: () => import("@/views/scenic-guide/components/ticket-list.vue")
  },
  {
    // 乘览点 列表
    path: "/tourist-attractions-list",
    meta: {},
    name: "tourist-attractions-list",
    component: () => import("@/views/scenic-guide/components/tourist-list.vue")
  },
  {
    // 租单车 列表
    path: "/bike-rental-list",
    meta: {},
    name: "ike-rental-list",
    component: () => import("@/views/scenic-guide/components/bike-rental.vue")
  },
  {
    // 观光车 列表
    path: "/bike-rental-list",
    meta: {},
    name: "bike-rental-list",
    component: () => import("@/views/scenic-guide/components/bike-rental.vue")
  },
  {
    // 乘游船 列表
    path: "/pleasure-boat-list",
    meta: {},
    name: "pleasure-boat-list",
    component: () => import("@/views/scenic-guide/components/pleasure-boat.vue")
  },
  {
    // 汽车站 列表
    path: "/bus-station-list",
    meta: {},
    name: "bus-station-list",
    component: () => import("@/views/scenic-guide/components/bus-station.vue")
  },
  {
    // 找茶馆 列表
    path: "/look-teahouse-list",
    meta: {},
    name: "look-teahouse-list",
    component: () => import("@/views/scenic-guide/components/look-teahouse.vue")
  },
  {
    // 找茶馆 详情
    path: "/teahouse-detial",
    meta: { index: 5 },
    name: "teahouse-detial",
    component: () => import("@/views/scenic-detial/teahouse-detail.vue")
  },
  {
    //个人中心
    path: "/personal-page",
    meta: {},
    name: "personal-page",
    component: () => import("@/views/personal-center/index.vue")
  },
  {
    //编辑 个人资料
    path: "/edit-profile",
    meta: { keepAlive: true },
    name: "edit-profile",
    component: () => import("@/views/personal-center/edit-profile.vue")
  },
  {
    //编辑 个人昵称
    path: "/edit-userName",
    meta: {},
    name: "edit-userName",
    component: () => import("@/views/personal-center/edit-userName.vue")
  },
  {
    // sos上报
    path: "/report",
    name: "report",
    component: () => import("@/views/report/index.vue")
  },
  {
    // 清除缓存
    path: "/clear",
    meta: { index: 1 },
    name: "clear",
    component: () => import("@/components/clear/index.vue")
  },
  {
    // 景区详情-景点介绍
    path: "/vr-plays",
    name: "vrplays",
    component: () => import("@/views/vrplays/index.vue")
  },
  {
    // 打点
    path: "/acquisition-point",
    name: "acquisitionPoint",
    component: () => import("@/views/acquisitionPoint/index.vue")
  },
  {
    // 百度地图打点列表
    path: "/baidu-pointList",
    name: "acquisitionPointList",
    component: () => import("@/views/acquisitionPoint/baiduPointList.vue")
  },
  {
    // 高德地图打点列表
    path: "/gaode-pointList",
    name: "gaodePointList",
    component: () => import("@/views/acquisitionPoint/gaodePointList.vue")
  }
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes
});

export default router;
