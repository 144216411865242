/**
 * [判断是否是微信浏览器]
 * @returns
 */
const isWechatBrowsers = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("micromessenger") !== -1) {
    return true;
  } else {
    return false;
  }
};
// 获取url参数
const getQueryVariable = (variable: string) => {
  return (
    decodeURIComponent(
      // @ts-ignore url解码中文字符
      (new RegExp("[?|&]" + variable + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
        // eslint-disable-next-line no-sparse-arrays
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || ""
  );
};

/**
 * 判断是否为安卓浏览器
 */
const isAndroid = () => {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) {
    return true;
  } else {
    return false;
  }
};

/**
 * WGS84坐标转换GCJ02坐标
 * @param $lat：wgs纬度
 * @param $lng：wgs经度
 * @return Array[lat:gcj纬度,lon:gcj经度]
 */
function Convert_BD09_To_GCJ02($lng: number, $lat: number) {
  let pi = 3.14159265358979324;
  let a = 6378245.0;
  let ee = 0.00669342162296594323;
  let mgLat, mgLon;
  if (outOfChina($lat, $lng)) {
    mgLat = $lat;
    mgLon = $lng;
    return;
  }
  let dLat = transformLat($lng - 105.0, $lat - 35.0);
  let dLon = transformLon($lng - 105.0, $lat - 35.0);
  let radLat = ($lat / 180.0) * pi;
  let magic = Math.sin(radLat);
  magic = 1 - ee * magic * magic;
  let sqrtMagic = Math.sqrt(magic);
  dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
  dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
  mgLat = $lat + dLat;
  mgLon = $lng + dLon;
  return [mgLon, mgLat];
}

function outOfChina(lat: number, lon: number) {
  if (lon < 72.004 || lon > 137.8347) return true;
  if (lat < 0.8293 || lat > 55.8271) return true;
  return false;
}
function transformLat(x: number, y: number) {
  let pi = 3.14159265358979324;
  let ee = 0.00669342162296594323;
  let ret =
    -100.0 +
    2.0 * x +
    3.0 * y +
    0.2 * y * y +
    0.1 * x * y +
    0.2 * Math.sqrt(Math.abs(x));
  ret +=
    ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
  ret +=
    ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) *
      2.0) /
    3.0;
  return ret;
}
function transformLon(x: number, y: number) {
  let pi = 3.14159265358979324;
  let ret =
    300.0 +
    x +
    2.0 * y +
    0.1 * x * x +
    0.1 * x * y +
    0.1 * Math.sqrt(Math.abs(x));
  ret +=
    ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
  ret +=
    ((150.0 * Math.sin((x / 12.0) * pi) + 300.0 * Math.sin((x / 30.0) * pi)) *
      2.0) /
    3.0;
  return ret;
}

export default {
  isWechatBrowsers,
  getQueryVariable,
  isAndroid,
  Convert_BD09_To_GCJ02
};
