/**
 * 自定义公共函数
 */
const utils = {
  /**
   * @name: 清除cookie值
   * @author: abc
   * @date: 2021-12-16
   * @param:  cname   string  cookie名称
   */
  setCookie(cname: string, cvalue: any, exdays = 1) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    // d.setTime(d.getTime() + (exdays * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  },
  /**
   * @name: 清除cookie值
   * @author: abc
   * @date: 2021-12-16
   * @param:  cname   string  cookie名称
   */
  getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) != -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  /**
   * @name: 清除cookie值
   * @author: abc
   * @date: 2021-12-16
   * @param:  cname   string  cookie名称
   */
  clearCookie(cname: string) {
    var d = new Date();
    d.setTime(-1);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=''; " + expires;
  }
};
export default utils;
