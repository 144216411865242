
import { Component, Vue } from "vue-property-decorator";
import { Button } from "vant";
Vue.use(Button);
@Component({ components: {} })
export default class DebunkDetial extends Vue {
  agree() {
    this.returnback(true);
  }
  returnback(param: boolean) {
    this.$emit("closedPrivacy", param);
  }
}
