// 移动端适配
import router from "../router";
(function(doc, win) {
  let docEl = doc.documentElement;
  let isIOS = navigator.userAgent.match(/iphone|ipod|ipad|android/gi);
  let dpr = isIOS ? Math.min(win.devicePixelRatio, 3) : 1;
  let resizeEvt =
    "orientationchange" in window ? "orientationchange" : "resize";

  (docEl.dataset.dpr as any) = dpr;

  // 判断是否有vierport;
  // 如果有则删除
  function removeViewport() {
    let meta = doc.getElementsByTagName("meta");
    let head = doc.getElementsByTagName("head")[0];
    for (let i = 0; i < meta.length; i++) {
      if (
        meta[i].getAttribute("name") === "viewport" ||
        meta[i].getAttribute("id") === "viewinfo"
      ) {
        console.log(i);
        head.removeChild(meta[i]);
      }
    }
  }
  // 创建节点
  function createViewport() {
    let head = doc.getElementsByTagName("head")[0];
    let metaEl = doc.createElement("meta");
    metaEl.setAttribute("name", "viewport");
    metaEl.setAttribute(
      "content",
      "width=device-width, initial-scale=" +
        1 / dpr +
        ", maximum-scale=" +
        1 / dpr +
        ", minimum-scale=" +
        1 / dpr +
        ", user-scalable=no"
    );

    head.insertBefore(metaEl, head.children[0]);
  }
  // 创建iframe 页面缩放  为了兼容设置
  function createViewportIframe() {
    let head = doc.getElementsByTagName("head")[0];
    let metaEl = doc.createElement("meta");
    metaEl.setAttribute("name", "viewport");
    metaEl.setAttribute("content", "width=750px, user-scalable=no");
    head.insertBefore(metaEl, head.children[0]);
    metaEl = doc.createElement("meta");
    metaEl.setAttribute("name", "viewport");
    metaEl.setAttribute("content", "");
    head.insertBefore(metaEl, head.children[1]);
    metaEl = doc.createElement("meta");
    metaEl.setAttribute("name", "viewport");
    metaEl.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
    );
    head.insertBefore(metaEl, head.children[2]);
  }
  let recalc = function() {
    let width = docEl.clientWidth;
    if (width / dpr > 750) {
      width = 750 * dpr;
    }
    docEl.style.fontSize = 100 * (width / 750) + "px";
  };
  router.afterEach(to => {
    removeViewport();
    if (to.name === "vrplays" || to.name === "videos-list") {
      createViewportIframe();
    } else {
      createViewport();
    }
    recalc();
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
  });
})(document, window);
