// @ts-nocheck
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Weixin from "@/util/user";
import "@/util/scale750";
import "vant/lib/index.css";
import "@/assets/styles/global.scss";
require("video.js/dist/video-js.css");
import comm from "@/util/common";
import CONFIG from "@/util/config";
import axios from "axios";
import vueTouch from "kim-vue-touch";
import {
  Toast,
  Dialog,
  Loading,
  Overlay,
  List,
  Swipe,
  SwipeItem,
  Rate,
  Uploader,
  ImagePreview,
  Tab,
  Tabs,
  Field,
  Cell,
  ActionSheet,
  Button,
  Form,
  Image,
  Radio,
  RadioGroup,
  DatetimePicker,
  Popup,
  Picker,
  NoticeBar,
  Icon,
  Progress,
  Search,
  NavBar,
  Popover,
  Grid,
  GridItem,
  Cascader,
  Checkbox,
  Switch
} from "vant";
// import { connect } from "http2";
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(List);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Uploader);
Vue.use(Rate);
Vue.use(ImagePreview);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(Cell);
Vue.use(ActionSheet);
Vue.use(Button);
Vue.use(Form);
Vue.use(Image);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(vueTouch);
Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(Progress);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(Popover);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cascader);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.config.productionTip = false;
// 这个是为了兼容 5G直播m3u8
const hls = require("videojs-contrib-hls");
Vue.use(hls);
let hisrouter = []; // 记录路由
//定义全局默认配置
// if(sessionStorage.getItem('token')){
//   axios.defaults.headers.common['ACCOUNT'] = store.state.userInfo.token;
// }
router.beforeEach((to: any, from: any, next) => {
  // 保存当前路由名
  store.commit("setRouterPath", to.path);
  if (to.meta.index >= from.meta.index) {
    store.state.transitionName = "slide-left";
  } else {
    store.state.transitionName = "slide-right";
  }
  // url中携带token参数时 才写入token  否则将使用存储的token
  if (comm.getQueryVariable("token")) {
    localStorage.setItem("token", comm.getQueryVariable("token"));
  }
  // 修改网站title
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  // 从index-home 进入导览页时，不使用缓存数据
  // if (from.name === "index-home" && to.name === "scenic-guide") {
  //   to.meta.useCash = false;
  // }
  // 从scenic-guide 进入景区简介时，不使用缓存数据
  if (from.name === "scenic-guide" && to.name === "scenic-introduction") {
    to.meta.useCash = false;
  }
  // || to.path === '/scenic-introduction'
  if (
    to.path === "/scenic-guide" ||
    to.path === "/scenic-introduction" ||
    to.path === "/edit-profile"
  ) {
    to.meta.keepAlive = true;
  }
  // 切换路由时，关闭图片预览
  store.commit("setShowimagePreview", false);

  if (document && document.body) {
    // @ts-ignore 路由变化时清空 冻结的body
    document.body.style = "";
  }

  next();
});

/**
 * 增加组件通信的中间组件 bus
 *
 * 发送事件
 * this.bus.$emit("onChangeAuditList");
 * 监听后的执行
 * this.bus.$on("onChangeAuditList", () => {});
 */
let bus = new Vue();
Vue.prototype.bus = bus;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
