import com from "./common";
import CONFIG from "@/util/config";
import store from "@/store";
import { getUserInfo } from "@/service/index";
// 获取微信code
const getWXCode = (url: string) => {
  return new Promise((resolve, reject) => {
    // 没有code 时授权获取微信code
    let _window = window.location.href;
    let _href = "";
    if (
      _window.indexOf("subscene") !== -1 ||
      _window.indexOf("scene") !== -1 ||
      _window.indexOf("clicktime") !== -1
    ) {
      // 微信自带参数
      _href = _window.split("#")[0].split("?")[0] + "#" + _window.split("#")[1];
    } else {
      _href = _window;
    }
    let redirectUri =
      _href.indexOf("?") !== -1
        ? _href.split("?")[0].replace(/#/g, "@")
        : _href.replace(/#/g, "@");
    if (com.isWechatBrowsers()) {
      let vcode = localStorage.getItem("vcode") || "";
      // 添加vcode
      redirectUri = `${redirectUri}?vcode=${vcode}`;

      // 将state参数替换为需要重定向的页面路由
      url = url.replace(
        /&state=STATE/gi,
        `&state=${encodeURIComponent(redirectUri)}`
      );
      window.location.href = url;
    }
  });
};

export default {
  // 获取用户信息
  getUserInfo() {
    return new Promise<void>(async (resolve, reject) => {
      getUserInfo().then(async res => {
        if (res.code === 999 && com.isWechatBrowsers()) {
          // 拉取微信授权
          getWXCode(res.data);
        } else if (res.code === 0) {
          // 设置用户信息
          // 判断有没有token，没有则表示第一次进入页面，需存入临时token
          if (!localStorage.getItem("token")) {
            localStorage.setItem("token", res.data.token);
          }
          localStorage.setItem("vcode", res.data.vcode);
          localStorage.setItem("jpjsOpenId", res.data.jpjsOpenId || ""); // 金牌解说openId
          resolve();
        }
      });
    });
  }
};
